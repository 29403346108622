import React from "react";
import Button from "./Button";
import { useCheckout, useOrderState, useCart } from "../contexts";

export default function ThankYou() {
  const { checkout } = useCheckout();
  const { setOrderState } = useOrderState();
  const { setCart } = useCart();
  return (
    <>
      <div className="grid-container hero">
        <div className="grid-x text-center">
          <div className="cell">
            <span className="hl-top">Děkujeme!</span>
            <h1 className="mb-3">Vaše objednávka byla přijata.</h1>
            <div className="grid-x align-center">
              <div className="cell large-8">
                <p className="mb-3">
                  Potvrzení o přijetí objednávky bylo odesláno na mail
                  <span className="strong"> {checkout.email}</span>.
                  {checkout.shipping === "delivery" && (
                    <span>
                      Nyní stačí jen vyčkat na doručení. Náš kurýr vás bude
                      kontaktovat
                    </span>
                  )}{" "}
                  {checkout.shipping === "pickup" && (
                    <span>
                      Až bude vaše objednávka připravena, budeme vás informovat{" "}
                    </span>
                  )}
                  na čísle <span className="strong">{checkout.phone}</span>{" "}
                  nejpozději do 60 minut.
                </p>
              </div>
            </div>
            <Button
              classes="cta"
              value="Zpět na hlavní stránku"
              action={() => {
                setOrderState("order");
                setCart([]);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
