import React, { createContext, useContext, useState } from "react";

export const AdressContext = createContext();
export const AdressProvider = ({ children }) => {
  const [adresses, setAdresses] = useState();

  return (
    <AdressContext.Provider value={{ adresses, setAdresses }}>
      {children}
    </AdressContext.Provider>
  );
};

export const useAdress = () => useContext(AdressContext);
