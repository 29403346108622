import React from "react";
import Header from "./Header";
import HomePage from "./HomePage";
import Checkout from "./Checkout";
import ThankYou from "./ThankYou";
import Footer from "./Footer";

import { useOrderState } from "../contexts";

export default function Layout() {
  const { orderState } = useOrderState();

  return (
    <div>
      <Header />
      {orderState === "order" ? <HomePage /> : ""}
      {orderState === "checkout" ? <Checkout /> : ""}
      {orderState === "thankyou" ? <ThankYou /> : ""}
      <Footer />
    </div>
  );
}
