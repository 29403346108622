import React, { createContext, useContext } from "react";
import { useMenu } from "../hooks";

export const MenuContext = createContext();
export const MenuProvider = ({ children }) => {
  const {
    menu,
    setMenu,
    ingredience,
    setIngredience,
    burgerIngredience,
    setBurgerIngredience,
    dailyMenu,
    setDailyMenu,
    settings,
    setSettings,
  } = useMenu();

  return (
    <MenuContext.Provider
      value={{
        menu,
        setMenu,
        ingredience,
        setIngredience,
        burgerIngredience,
        setBurgerIngredience,
        dailyMenu,
        setDailyMenu,
        settings,
        setSettings,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuValue = () => useContext(MenuContext);
