import React, { useState, useEffect } from "react";
import {
  useCart,
  useOrderTotal,
  useOrderState,
  useMenuValue,
} from "../contexts";
import Close from "./Close";
import Cart from "./Cart";
import SurchargeConfirm from "./SurchargeConfirm";
import Button from "./Button";
import { isNowOpen } from "../utils/openHours";
import { firebase } from "../firebase";

export default function SmallCart() {
  const [isOpen, setIsOpen] = useState(false);
  const [showSurchargeConfirm, setShowSurchargeConfirm] = useState(false);
  const { cart, setCart } = useCart();
  const { setOrderState } = useOrderState();
  const { total } = useOrderTotal();
  const { settings } = useMenuValue();

  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    } else if (cart.length === 0) {
      const storedCart = JSON.parse(localStorage.getItem("cart"));
      if (storedCart && storedCart.length > 0) {
        setCart(storedCart);
      }
    }
  }, [cart]);

  function getSuffix(count) {
    if (count === 1) {
      return "položka";
    }
    if (count > 1 && count < 5) {
      return "položky";
    }
    if (count >= 5) {
      return "položek";
    }
  }

  function isDeliveryOpen() {
    if (settings) {
      const permanentMenuOpen = isNowOpen(
        settings.permanentmenudelivery[0].dayofweek
      );
      return permanentMenuOpen;
    }
  }

  function nextStep(e) {
    e.preventDefault();
    if (total.surcharge > 0) {
      setShowSurchargeConfirm(true);
    } else {
      setOrderState("checkout");
      firebase.analytics().logEvent("begin_checkout", {
        currency: "CZK",
        value: total.orderTotal,
      });
      window.scrollTo(0, 0);
    }
  }
  return (
    <>
      {!isOpen && cart.length > 0 && (
        <div
          className="cart-bar"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {" "}
          Vaše objednávka{" "}
          <span>
            ({total.itemCount} {getSuffix(total.itemCount)} za{" "}
            {total.orderPrice + total.packaging} Kč)
          </span>
        </div>
      )}
      <div className={`cart-panel ${isOpen && "open"}`}>
        <div className="grid-x align-justify cart-topbar">
          <div className="cell small-2">
            <span
              className="action empty"
              onClick={() => {
                setCart([]);
                localStorage.removeItem("cart");
                setIsOpen(false);
              }}
            >
              Vyprázdnit
            </span>
          </div>
          <div className="cell auto text-center title">Vaše objednávka</div>
          <div className="cell small-2 text-right">
            <Close
              label="Zavřít"
              classes="cart-topbar-close"
              action={() => {
                setIsOpen(false);
              }}
            />
          </div>
        </div>
        <div className="cart-wrapper" id="cart">
          <Cart controls={true} heightControl={true} />
          <Button
            classes="cta full"
            disabled={cart.length < 1 || !isDeliveryOpen()}
            value={
              isDeliveryOpen()
                ? "Pokračovat v objednávce"
                : "Nyní si nelze objednat"
            }
            action={nextStep}
          />
        </div>
      </div>
      {showSurchargeConfirm && (
        <SurchargeConfirm
          setShowSurchargeConfirm={setShowSurchargeConfirm}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
}
