import React from "react";

export default function QtyControl({ type, action }) {
  return (
    <span className="qty-control" onClick={action}>
      {type === "minus" ? (
        <svg
          width="8"
          height="2"
          viewBox="0 0 8 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.249512" width="8" height="1.5" rx="0.75" fill="#CF2A36" />
        </svg>
      ) : (
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="3.50049" width="8" height="1" rx="0.5" fill="#5FDF22" />
          <rect
            x="3.50391"
            y="8"
            width="8"
            height="1"
            rx="0.5"
            transform="rotate(-90 3.50391 8)"
            fill="#5FDF22"
          />
        </svg>
      )}
    </span>
  );
}
