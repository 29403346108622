import React from "react";
// import Logo from "./Logo";

export default function Header() {
  return (
    <header>
      <div className="grid-container">
        <div className="grid-x align-middle">
          <div className="cell shrink">
            {/* <Logo /> */}
            <img
              src="cimelice-logo.png"
              alt="logo restaurace Pizza Burger Čimelice"
              style={{ maxWidth: 120 }}
            />
          </div>
          <div className="cell auto text-right">
            <span className="head-contact">
              <a className="telephone" href="tel:606115119">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.8889 13.8889C17.5056 13.8889 16.1667 13.6667 14.9222 13.2556C14.5389 13.1333 14.1 13.2222 13.7945 13.5278L11.3501 15.9778C8.20005 14.3778 5.62781 11.8056 4.02781 8.66115L6.47224 6.20557C6.77781 5.9 6.86667 5.46115 6.74448 5.07781C6.33339 3.83339 6.11115 2.49448 6.11115 1.11115C6.11109 0.494427 5.61667 0 5 0H1.11109C0.5 0 0 0.494427 0 1.11109C0 11.5444 8.45557 20 18.8889 20C19.5056 20 20 19.5056 20 18.8889V15C20 14.3833 19.5056 13.8889 18.8889 13.8889Z"
                    fill="#CF2A36"
                  />
                </svg>
                606 115 119
              </a>
            </span>
          </div>
        </div>
      </div>
    </header>
  );
}
