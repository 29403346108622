import { useState, useEffect } from "react";
import { firestore, auth } from "../firebase";
import axios from "axios";

export const useMenu = () => {
  const [menu, setMenu] = useState([]);
  const [ingredience, setIngredience] = useState([]);
  const [burgerIngredience, setBurgerIngredience] = useState([]);
  const [dailyMenu, setDailyMenu] = useState([]);
  const [settings, setSettings] = useState();

  useEffect(() => {
    async function fetchMeals() {
      const data = await axios.get(
        `https://pizza-burger-cimelice.cz/wp-json/wp/v2/jidla?per_page=20`
      );
      const jidla = data.data.filter(
        (item) =>
          item.id !== 143 &&
          item.id !== 169 &&
          item.id !== 168 &&
          item.id !== 356
      );
      const ingredience = data.data.filter((item) => item.id === 143);
      const burgerIngredience = data.data.filter((item) => item.id === 356);
      const dailyMenu = data.data.filter((item) => item.id === 169);
      const settings = data.data.filter((item) => item.id === 168);
      setMenu(jidla);
      setIngredience(ingredience);
      setBurgerIngredience(burgerIngredience);
      setDailyMenu(dailyMenu);
      setSettings(settings[0].ACF);
    }

    fetchMeals();
  }, []);

  return {
    menu,
    setMenu,
    ingredience,
    setIngredience,
    burgerIngredience,
    setBurgerIngredience,
    settings,
    setSettings,
    dailyMenu,
    setDailyMenu,
  };
};

export const useAuthentication = () => {
  const [authUser, setAuthUser] = useState(null);

  // useEffect(() => {
  //   auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       const userRef = firestore.collection("users").doc(user.uid);
  //       userRef.get().then(function (doc) {
  //         if (doc.exists) {
  //           setAuthUser({
  //             ...user,
  //             role: doc.data().role,
  //           });
  //         } else {
  //           console.log("Uživatel neexistuje.");
  //         }
  //       });
  //     } else {
  //       setAuthUser(null);
  //     }
  //   });
  // });

  return {
    authUser,
    setAuthUser,
  };
};
