import React, { useState } from "react";

export default function CheckoutPanel({ label, children, icon, fields, func }) {
  const [isOpen, setIsOpen] = useState(true);
  const setIcon = function(icon) {
    if (icon === "user") {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.88501 7.70724C8.94385 7.70724 9.86072 7.32749 10.6099 6.57822C11.359 5.82908 11.7388 4.91246 11.7388 3.8535C11.7388 2.79491 11.359 1.87817 10.6097 1.12878C9.86047 0.379759 8.94373 0 7.88501 0C6.82605 0 5.90942 0.379759 5.16028 1.1289C4.41113 1.87804 4.03125 2.79479 4.03125 3.8535C4.03125 4.91246 4.41113 5.8292 5.1604 6.57834C5.90967 7.32736 6.82642 7.70724 7.88501 7.70724Z"
            fill="#CF2A36"
          />
          <path
            d="M14.629 12.3031C14.6074 11.9913 14.5637 11.6512 14.4994 11.2921C14.4344 10.9303 14.3508 10.5882 14.2507 10.2756C14.1473 9.9525 14.0067 9.63341 13.8329 9.32763C13.6525 9.01025 13.4406 8.73388 13.2028 8.50647C12.9541 8.26855 12.6497 8.07727 12.2976 7.93774C11.9468 7.79895 11.558 7.72864 11.1421 7.72864C10.9788 7.72864 10.8208 7.79565 10.5157 7.99426C10.328 8.1167 10.1084 8.2583 9.86328 8.41491C9.65369 8.54846 9.36975 8.67358 9.01904 8.78686C8.67688 8.89758 8.32947 8.95373 7.98657 8.95373C7.64368 8.95373 7.29639 8.89758 6.95386 8.78686C6.60352 8.6737 6.31958 8.54858 6.11023 8.41504C5.86743 8.25989 5.64771 8.11828 5.45715 7.99414C5.15247 7.79553 4.99438 7.72852 4.83105 7.72852C4.41504 7.72852 4.02637 7.79895 3.67566 7.93787C3.32385 8.07715 3.01929 8.26843 2.77039 8.50659C2.53271 8.73413 2.32068 9.01037 2.1405 9.32763C1.9668 9.63341 1.82617 9.95238 1.72266 10.2757C1.62268 10.5884 1.53906 10.9303 1.47412 11.2921C1.40979 11.6507 1.36609 11.9909 1.34448 12.3034C1.32324 12.6096 1.3125 12.9273 1.3125 13.2483C1.3125 14.0835 1.578 14.7596 2.10156 15.2583C2.61865 15.7503 3.30286 16 4.13489 16H11.839C12.671 16 13.355 15.7505 13.8722 15.2583C14.3959 14.76 14.6614 14.0837 14.6614 13.2481C14.6613 12.9258 14.6504 12.6078 14.629 12.3031Z"
            fill="#CF2A36"
          />
        </svg>
      );
    } else if (icon === "delivery") {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.1998 10.3467C12.4499 10.3467 11.8398 10.9569 11.8398 11.7067C11.8398 12.4566 12.4499 13.0667 13.1998 13.0667C13.9496 13.0667 14.5598 12.4566 14.5598 11.7067C14.5598 10.9569 13.9496 10.3467 13.1998 10.3467ZM13.1998 12.1067C12.9792 12.1067 12.7998 11.9273 12.7998 11.7067C12.7998 11.4861 12.9792 11.3067 13.1998 11.3067C13.4205 11.3067 13.5998 11.4861 13.5998 11.7067C13.5998 11.9273 13.4205 12.1067 13.1998 12.1067Z"
            fill="#CF2A36"
          />
          <path
            d="M13.0701 4.69336H10.7204C10.5436 4.69336 10.4004 4.83673 10.4004 5.01336V11.2534C10.4004 11.43 10.5436 11.5734 10.7204 11.5734H11.0598C11.2182 11.5734 11.3585 11.4593 11.379 11.3022C11.5045 10.3399 12.2721 9.74314 13.2005 9.74314C14.129 9.74314 14.8965 10.3399 15.0221 11.3022C15.0426 11.4593 15.1828 11.5734 15.3413 11.5734H15.6804C15.857 11.5734 16.0004 11.43 16.0004 11.2534V8.13336C16.0004 8.05817 15.974 7.98552 15.9258 7.92808L13.3156 4.80808C13.2548 4.73545 13.1649 4.69336 13.0701 4.69336ZM11.0404 7.25336V5.65336C11.0404 5.47673 11.1836 5.33336 11.3604 5.33336H12.77C12.8649 5.33336 12.955 5.37561 13.0157 5.44855L14.349 7.04855C14.5226 7.25702 14.3745 7.57336 14.1031 7.57336H11.3604C11.1836 7.57336 11.0404 7.43017 11.0404 7.25336Z"
            fill="#CF2A36"
          />
          <path
            d="M0.32 11.5736H1.13937C1.29778 11.5736 1.43809 11.4595 1.45856 11.3024C1.58416 10.3402 2.35169 9.74339 3.28016 9.74339C4.20863 9.74339 4.97616 10.3402 5.10175 11.3024C5.12222 11.4595 5.26238 11.5736 5.42094 11.5736H9.44C9.61665 11.5736 9.76 11.4302 9.76 11.2536V3.25359C9.76 3.07697 9.61663 2.93359 9.44 2.93359H0.32C0.14319 2.93359 0 3.07697 0 3.25359V11.2536C0 11.4303 0.14319 11.5736 0.32 11.5736Z"
            fill="#CF2A36"
          />
          <path
            d="M3.27992 10.3467C2.53001 10.3467 1.91992 10.9569 1.91992 11.7067C1.91992 12.4566 2.53001 13.0667 3.27992 13.0667C4.02967 13.0667 4.63992 12.4566 4.63992 11.7067C4.63992 10.9569 4.02967 10.3467 3.27992 10.3467ZM3.27992 12.1067C3.05929 12.1067 2.87992 11.9273 2.87992 11.7067C2.87992 11.4861 3.05929 11.3067 3.27992 11.3067C3.50055 11.3067 3.67992 11.4861 3.67992 11.7067C3.67992 11.9273 3.50055 12.1067 3.27992 12.1067Z"
            fill="#CF2A36"
          />
        </svg>
      );
    } else if (icon === "payment") {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M14.9089 13.0909H12.3634C10.7594 13.0909 9.45441 11.7859 9.45441 10.1819C9.45441 8.57788 10.7594 7.27287 12.3634 7.27287H14.9089C15.1099 7.27287 15.2725 7.11022 15.2725 6.90923V5.81834C15.2725 5.0558 14.681 4.43566 13.9337 4.37553L11.8454 0.727861C11.6518 0.390503 11.3393 0.149382 10.9654 0.0492583C10.5932 -0.0501832 10.2041 0.00204321 9.87096 0.195915L2.71189 4.3638H1.45453C0.652353 4.3638 0 5.01612 0 5.81834V14.5455C0 15.3476 0.652319 16 1.45453 16H13.818C14.6202 16 15.2725 15.3477 15.2725 14.5455V13.4546C15.2725 13.2536 15.1099 13.0909 14.9089 13.0909ZM12.2964 2.97987L13.0887 4.3638H9.91927L12.2964 2.97987ZM4.15732 4.3638L10.2371 0.824439C10.4015 0.728202 10.5936 0.702634 10.7772 0.751656C10.9629 0.80136 11.1178 0.921392 11.214 1.08936L11.2147 1.09068L5.5929 4.3638H4.15732Z"
              fill="#CF2A36"
            />
            <path
              d="M14.9088 8H12.3634C11.1603 8 10.1816 8.97867 10.1816 10.1818C10.1816 11.3849 11.1603 12.3636 12.3634 12.3636H14.9088C15.5104 12.3636 15.9997 11.8742 15.9997 11.2727V9.09089C15.9997 8.48933 15.5104 8 14.9088 8ZM12.3634 10.909C11.9625 10.909 11.6362 10.5827 11.6362 10.1818C11.6362 9.78088 11.9625 9.45453 12.3634 9.45453C12.7643 9.45453 13.0907 9.78088 13.0907 10.1818C13.0907 10.5827 12.7644 10.909 12.3634 10.909Z"
              fill="#CF2A36"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    } else if (icon === "adress") {
      return (
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 16C6 16 12 9.31372 12 6C12 2.68628 9.31372 0 6 0C2.68628 0 0 2.68628 0 6C0 9.31372 6 16 6 16ZM6 8C7.10455 8 8 7.10455 8 6C8 4.89545 7.10455 4 6 4C4.89545 4 4 4.89545 4 6C4 7.10455 4.89545 8 6 8Z"
            fill="#CF2A36"
          />
        </svg>
      );
    }
  };
  return (
    <div className="checkoutPanel">
      <div
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`grid-x align-justify align-middle checkoutPanel-head ${
          isOpen ? "is-open" : ""
        }`}
      >
        <div className="cell shrink title">
          <div className="icon">{setIcon(icon)}</div>
          <div className="label">
            {isOpen
              ? label
              : fields.every(el => el !== "" && el !== null)
              ? fields.map((item, index) =>
                  index === 0 ? (
                    <span className="strong checkoutPanel-fields" key={index}>
                      {func ? func(item) : item}
                    </span>
                  ) : (
                    <span className="checkoutPanel-fields" key={index}>
                      {item}
                    </span>
                  )
                )
              : label}
          </div>
        </div>
        <div className="cell shrink">
          <span className="link">{isOpen ? "Zmenšit" : "Změnit"}</span>
        </div>
      </div>

      <div className={`checkoutPanel-content ${isOpen ? "is-open" : ""}`}>
        <div className="checkoutPanel-content--wrap">{children}</div>
      </div>
    </div>
  );
}
