export function amountSale(cart, setCart) {
  const itemsOnSale = cart.filter(
    (item) => item.saleType === "fixed" || item.saleType === "percent"
  );

  if (itemsOnSale.length > 0) {
    itemsOnSale.forEach((item) => {
      let priceToSet = item.price;
      if (item.saleType === "fixed") {
        priceToSet = parseInt(item.regularPrice) - parseInt(item.slevaKc);
      } else {
        const onePercent = parseInt(item.regularPrice) / 100;
        const sale = onePercent * parseInt(item.percent);
        const preRoundedPrice = parseInt(item.regularPrice) - sale;
        priceToSet = preRoundedPrice.toFixed(0);
      }
      if (priceToSet !== item.price) {
        let idx = 0;
        for (let el of cart) {
          if (el.name === item.name && el.cat === item.cat) {
            break;
          } else {
            idx++;
          }
        }

        let newCart = [...cart];
        newCart[idx].price = priceToSet;
        setCart(newCart);
      }
    });
  }
}
