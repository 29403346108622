import React from "react";
import Modal from "./Modal";
import Close from "./Close";
import Button from "./Button";
import { firebase } from "../firebase";
import { useOrderState, useOrderTotal } from "../contexts";
import { useMediaQuery } from "react-responsive";

export default function SurchargeConfirm({
  setShowSurchargeConfirm,
  setIsOpen,
}) {
  const { setOrderState } = useOrderState();
  const { total } = useOrderTotal();
  const isNotLarge = useMediaQuery({ query: "(max-width: 1023px)" });

  function continueShopping() {
    setShowSurchargeConfirm(false);
    if (isNotLarge) {
      setIsOpen(false);
    }
  }
  return (
    <Modal size="small">
      <div className="modal-content">
        <span className="modal-headline">
          Minimální hodnota objednávky je 200 Kč.
        </span>
        <p className="text-center">
          Hodnota vaší objednávky je{" "}
          <span className="strong">
            {total.orderPrice + total.packaging} Kč
          </span>
          .
        </p>
        <p className="text-center">
          Je třeba doplatit{" "}
          <span className="strong">
            {200 - (total.orderPrice + total.packaging)} Kč
          </span>
          .
        </p>
        <div className="info success">
          V případě osobního vyzvednutí se doplatek neplatí. Osobní vyzvednutí
          můžete vybrat v dalším kroku objednávky.
        </div>
        <div className="button-group text-center">
          <Button
            classes="link"
            value="Vybrat další jídlo"
            action={() => {
              continueShopping();
            }}
          />
          <Button
            value="Ano, pokračovat"
            action={() => {
              setOrderState("checkout");
              firebase.analytics().logEvent("begin_checkout", {
                currency: "CZK",
                value: total.orderTotal,
              });
              window.scrollTo(0, 0);
            }}
          />
        </div>
      </div>
      <Close
        label="Zavřít"
        action={() => {
          setShowSurchargeConfirm(false);
        }}
      />
    </Modal>
  );
}
