import React from "react";
import OpenHours from "./OpenHours";

export default function Hero() {
  return (
    <>
      <div className="grid-container hero">
        <div className="grid-x text-center">
          <div className="cell">
            <span className="hl-top">Pizza - Burger Čimelice</span>
            <h1>Rozvoz jídla po Čimelicích</h1>
            <div className="bullets">
              <span>Do 90 min (dle vytížení)</span>
              <span>Minimální objednávka 150 Kč </span>
            </div>
            <OpenHours />
          </div>
        </div>
      </div>
    </>
  );
}
