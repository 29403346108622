import React, { useEffect } from "react";
import CartItem from "./CartItem";
import CartTotal from "./CartTotal";
import {
  makeSlug,
  calculatePackaging,
  calculateOrderPrice,
  calculateDelivery,
  calculateSurcharge,
  calculateCartHeight,
  getCartCount,
} from "../utils";
import { menuSale, xPlusOne, amountSale } from "../utils/sales/";
import { useOrderTotal, useCart, useCheckout } from "../contexts";

export default function Cart({ controls, heightControl }) {
  const { total, setTotal } = useOrderTotal();
  const { cart, setCart } = useCart();
  const { checkout } = useCheckout();

  useEffect(() => {
    if (heightControl) {
      calculateCartHeight(cart);
    }
    const orderPrice = calculateOrderPrice(cart);
    const packaging = calculatePackaging(cart);
    const delivery = calculateDelivery(cart, checkout);
    const surcharge = calculateSurcharge(cart, checkout);
    const items = getCartCount(cart);
    const orderTotal = orderPrice + packaging + delivery + surcharge;
    setTotal({
      orderPrice: orderPrice,
      packaging: packaging,
      delivery: delivery,
      orderTotal: orderTotal,
      surcharge: surcharge,
      itemCount: items,
    });
  }, [cart, checkout]);

  useEffect(() => {
    menuSale(cart, setCart);
    xPlusOne(cart, setCart);
    amountSale(cart, setCart);
  }, [cart]);

  return (
    <>
      <div className="cart-content">
        {cart.map((i, index) => (
          <CartItem
            key={makeSlug(`${i.catName}-${i.name}-${index}`)}
            i={i}
            controls={controls}
            cart={cart}
            setCart={setCart}
          />
        ))}
      </div>
      <CartTotal total={total} checkout={checkout} />
    </>
  );
}
