import React, { createContext, useContext } from "react";
import { useAuthentication } from "../hooks";

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const { authUser, setAuthUser } = useAuthentication();

  return (
    <AuthContext.Provider value={{ authUser, setAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
