export function dayOfWeek() {
  const today = new Date();
  const todayName = today.toLocaleDateString("cs-CZ", { weekday: "long" });
  const todayNameCaps = todayName.charAt(0).toUpperCase() + todayName.slice(1);
  return todayNameCaps;
}

export function isOpenHour(openTime, closeTime) {
  const date = new Date();
  const year = date.getFullYear();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const dateOnly = `${year}/${month}/${day}`;
  const currentDate = new Date(
    `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`
  ).getTime();
  const dateOpen = new Date(dateOnly + ` ${openTime}:00`).getTime();
  const dateClose = new Date(dateOnly + ` ${closeTime}:00`).getTime();

  return currentDate > dateOpen && currentDate < dateClose;
}

export function isNowOpen(data) {
  const today = dayOfWeek();
  const todayData = data.filter((day) => day.dayname === today);
  if (todayData.length > 0) {
    const isOpen = isOpenHour(
      todayData[0].deliverystart,
      todayData[0].deliveryends
    );
    if (isOpen) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function isDayToday(date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}
