import { uniqBy } from "lodash";

export function menuSale(cart, setCart) {
  const itemsOnSale = cart.filter((item) => item.saleType === "menu");

  if (itemsOnSale.length > 0) {
    const uniqueItems = uniqBy(itemsOnSale, "name");

    uniqueItems.forEach((item) => {
      let itemQty = parseInt(item.qty);

      if (itemsOnSale.length > uniqueItems.length) {
        itemQty = itemsOnSale
          .filter((i) => i.name === item.name && i.cat === item.cat)
          .reduce((acc, el) => {
            acc += parseInt(el.qty);
            return acc;
          }, 0);
      }

      const otherCartItems = cart.filter((cartItem) => {
        return item.menuWith.includes(cartItem.mealCategory);
      });
      let otherCartItemsQty = 0;
      if (otherCartItems.length > 0) {
        otherCartItemsQty = otherCartItems.reduce((acc, el) => {
          acc += parseInt(el.qty);
          return acc;
        }, 0);
      }

      if (otherCartItemsQty === 0) {
        //nemame zadne polozky, se kterymi je polozka slevnena v menu

        if (itemsOnSale.length > 1) {
          //pokud mame vice polozek se slevou v menu, kontrolujeme, jestli jsou tam dve stejne
          const itemToUpdate = itemsOnSale.filter(
            (i) => i.name === item.name && i.cat === item.cat
          );

          if (itemToUpdate.length > 1) {
            //pokud mame vice polozek, sloucime je. cenu updatujeme v dalsim renderu
            const reducedCart = uniqBy(itemToUpdate, "name");

            let idx = 0;
            for (let el of reducedCart) {
              if (el.name === item.name && el.cat === item.cat) {
                break;
              } else {
                idx++;
              }
            }

            if (cart[idx].qty !== itemQty) {
              let newCart = [...reducedCart];
              newCart[idx].qty = itemQty;
              setCart(newCart);
            }
          } else {
            //pokud nemame vice polozek, rovnou updatujeme cenu, neni li jiz slevnena

            let idx = 0;
            for (let el of cart) {
              if (el.name === item.name && el.cat === item.cat) {
                break;
              } else {
                idx++;
              }
            }

            if (item.price !== item.regularPrice) {
              let newCart = [...cart];
              newCart[idx].price = item.regularPrice;
              setCart(newCart);
            }
          }
        } else {
          //pokud nemame vice polozek, rovnou updatujeme cenu

          let idx = 0;
          for (let el of cart) {
            if (el.name === item.name && el.cat === item.cat) {
              break;
            } else {
              idx++;
            }
          }

          if (item.price !== item.regularPrice) {
            let newCart = [...cart];
            newCart[idx].price = item.regularPrice;
            setCart(newCart);
          }
        }
      }

      if (itemQty <= otherCartItemsQty && otherCartItemsQty > 0) {
        //mame splnenou podminku, aby vsechny polozky mohly by slevneny.

        if (itemsOnSale.length > 1) {
          //pokud mame vice polozek se slevou v menu, kontrolujeme, jestli jsou tam dve stejne
          const itemToUpdate = itemsOnSale.filter((i) => i.name === item.name);

          if (itemToUpdate.length > 1) {
            //pokud mame vice polozek, sloucime je

            const reducedCart = cart.filter(
              (i) =>
                i.name !== item.name ||
                (i.name === item.name && i.price !== item.regularPrice)
            );

            let idx = 0;
            for (let el of reducedCart) {
              if (el.name === item.name && el.cat === item.cat) {
                break;
              } else {
                idx++;
              }
            }

            if (cart[idx].qty !== itemQty) {
              let newCart = [...reducedCart];
              newCart[idx].qty = itemQty;
              setCart(newCart);
            }
          } else {
            //pokud nemame vice polozek, rovnou updatujeme cenu, neni li jiz slevnena
            let idx = 0;
            for (let el of cart) {
              if (el.name === item.name && el.cat === item.cat) {
                break;
              } else {
                idx++;
              }
            }

            if (item.price !== item.menuPrice) {
              let newCart = [...cart];
              newCart[idx].price = item.menuPrice;
              setCart(newCart);
            }
          }
        } else {
          //pokud nemame vice polozek, rovnou updatujeme cenu
          let idx = 0;
          for (let el of cart) {
            if (el.name === item.name && el.cat === item.cat) {
              break;
            } else {
              idx++;
            }
          }

          if (item.price !== item.menuPrice) {
            let newCart = [...cart];
            newCart[idx].price = item.menuPrice;
            setCart(newCart);
          }
        }
      }

      if (itemQty > otherCartItemsQty && otherCartItemsQty > 0) {
        //nektere polozky mohou byt slevneny, jine nikoliv

        const saledItems = otherCartItemsQty; //pocet polozek, ktere mohou by slevneny
        const notSaledItems = itemQty - otherCartItemsQty; //pocet polozek, ktere nemohou byt slevneny

        //pokud polozka neni rozdelena, rozdelime ji
        if (itemsOnSale.length === uniqueItems.length) {
          //najdeme polozku, kterou chceme rozdelit
          let idx = 0;
          for (let el of cart) {
            if (el.name === item.name && el.cat === item.cat) {
              break;
            } else {
              idx++;
            }
          }

          let itemToCopy = { ...cart[idx] };
          let newCart = [...cart];
          newCart[idx].price = item.menuPrice;
          newCart[idx].qty = saledItems;
          itemToCopy.price = item.regularPrice;
          itemToCopy.qty = notSaledItems;

          let cartToSet = [...newCart, itemToCopy];
          setCart(cartToSet);
        } else {
          //pokud polozka je rozdelena, pouze aktualizujeme qty

          let idxL = 0; //index slevnene polozky
          for (let el of cart) {
            if (
              el.name === item.name &&
              el.cat === item.cat &&
              el.price === item.menuPrice
            ) {
              break;
            } else {
              idxL++;
            }
          }
          let idxR = 0; //index neslevnene polozky
          for (let el of cart) {
            if (
              el.name === item.name &&
              el.cat === item.cat &&
              el.price === item.regularPrice
            ) {
              break;
            } else {
              idxR++;
            }
          }

          if (
            cart[idxL].qty !== saledItems ||
            cart[idxR].qty !== notSaledItems
          ) {
            let newCart = [...cart];
            newCart[idxL].qty = saledItems;
            newCart[idxR].qty = notSaledItems;
            setCart(newCart);
          }
        }
      }
    });
  }
}
