import { uniqBy } from "lodash";

export function xPlusOne(cart, setCart) {
  const itemsOnSale = cart.filter((item) => item.saleType === "xplusone");

  if (itemsOnSale.length > 0) {
    const uniqueItems = uniqBy(itemsOnSale, "name");

    uniqueItems.forEach((item) => {
      let itemQty = parseInt(item.qty);
      const oneSet = parseInt(item.requiredQty) + 1;
      if (itemsOnSale.length > uniqueItems.length) {
        itemQty = itemsOnSale
          .filter((i) => i.name === item.name && i.cat === item.cat)
          .reduce((acc, el) => {
            acc += parseInt(el.qty);
            return acc;
          }, 0);
      }
      if (itemQty >= oneSet) {
        const itemsToSale = Math.floor(itemQty / oneSet);
        const notSaledItems = itemQty - itemsToSale;
        const itemToUpdate = itemsOnSale.filter(
          (i) => i.name === item.name && i.cat === item.cat
        );

        if (itemToUpdate.length === 1) {
          let idx = 0;
          for (let el of cart) {
            if (el.name === item.name && el.cat === item.cat) {
              break;
            } else {
              idx++;
            }
          }
          if (itemQty !== cart[idx].qty + itemsToSale) {
            let itemToCopy = { ...cart[idx] };
            let newCart = [...cart];
            newCart[idx].qty = notSaledItems;
            itemToCopy.qty = itemsToSale;
            itemToCopy.price = 0;
            setCart([...newCart, itemToCopy]);
          }
        } else {
          let idxR = 0;
          for (let el of cart) {
            if (
              el.name === item.name &&
              el.cat === item.cat &&
              el.regularPrice === item.regularPrice
            ) {
              break;
            } else {
              idxR++;
            }
          }

          let idxL = 0;
          for (let el of cart) {
            if (
              el.name === item.name &&
              el.cat === item.cat &&
              el.price === 0
            ) {
              break;
            } else {
              idxL++;
            }
          }

          if (itemQty !== cart[idxR].qty + itemsToSale) {
            let newCart = [...cart];
            newCart[idxR].qty = notSaledItems;
            newCart[idxL].qty = itemsToSale;
            setCart(newCart);
          }
        }
      } else {
        const itemToUpdate = itemsOnSale.filter(
          (i) => i.name === item.name && i.cat === item.cat
        );

        if (itemToUpdate.length > 1) {
          const reducedCart = cart.filter(
            (i) =>
              i.name !== item.name ||
              (i.price !== item.regularPrice && i.name === item.name)
          );

          let idx = 0;
          for (let el of reducedCart) {
            if (el.name === item.name && el.cat === item.cat) {
              break;
            } else {
              idx++;
            }
          }

          let newCart = [...reducedCart];
          newCart[idx].qty = itemQty;
          setCart(newCart);
        } else {
          if (item.price !== item.regularPrice) {
            let idx = 0;
            for (let el of cart) {
              if (el.name === item.name && el.cat === item.cat) {
                break;
              } else {
                idx++;
              }
            }
            let newCart = [...cart];
            newCart[idx].price = item.regularPrice;
            setCart(newCart);
          }
        }
      }
    });
  }
}
