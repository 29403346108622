import React from "react";
import Tooltip from "./Tooltip";

export default function CartTotal({ total, checkout }) {
  return (
    <>
      <div className="surcharge">
        <div className="grid-x align-middle align-justify surcharge-item">
          <div className="cell shrink surcharge-item--label">Balné</div>
          <div className="cell shrink surcharge-item--price">
            {total.packaging} Kč
          </div>
        </div>
        {checkout.shipping !== "pickup" && (
          <div className="grid-x align-middle align-justify surcharge-item">
            <div className="cell shrink surcharge-item--label">
              Doprava
              <Tooltip label="?" id="doprava">
                V případě osobního vyzvednutí dopravu neplatíte.
              </Tooltip>
            </div>
            <div className="cell shrink surcharge-item--price">
              {total.delivery} Kč
            </div>
          </div>
        )}
        {checkout.shipping !== "pickup" && total.surcharge > 0 && (
          <div className="grid-x align-middle align-justify surcharge-item">
            <div className="cell shrink surcharge-item--label">
              Doplatek do minimální objednávky{" "}
              <Tooltip label="?" id="min-objednavka">
                V případě osobního vyzvednutí doplatek do minimální objednávky
                neplatíte.
              </Tooltip>
            </div>
            <div className="cell shrink surcharge-item--price">
              {total.surcharge} Kč
            </div>
          </div>
        )}
      </div>
      <div className="total-price grid-x align-middle align-justify">
        <div className="cell shrink total-price--label">Cena celkem</div>
        <div className="cell shrink total-price--price">
          {total.orderTotal} Kč
        </div>
      </div>
    </>
  );
}
