import React, { useEffect } from "react";
import Hero from "./Hero";
import Anchors from "./Anchors";
import Products from "./Products";
import LargeCart from "./LargeCart";
import SmallCart from "./SmallCart";
import { useMediaQuery } from "react-responsive";
import { useCheckout } from "../contexts";

export default function HomePage() {
  const isLarge = useMediaQuery({ query: "(min-width: 1024px)" });
  const isNotLarge = useMediaQuery({ query: "(max-width: 1023px)" });
  const isSmall = useMediaQuery({ query: "(max-width: 640px)" });
  const isNotSmall = useMediaQuery({ query: "(min-width: 641px)" });
  const { checkout, setCheckout } = useCheckout();

  useEffect(() => {
    const storedCheckout = JSON.parse(localStorage.getItem("checkout"));
    if (
      storedCheckout &&
      checkout.name === "" &&
      checkout.phone === "" &&
      checkout.email === "" &&
      checkout.adress === null
    ) {
      setCheckout(storedCheckout);
    }
  }, []);

  return (
    <>
      <Hero />
      <div className="grid-container">
        <div className="grid-x grid-margin-x apple-problem">
          {isSmall && (
            <div className="cell small-12">
              <Products tabs={true} />
            </div>
          )}
          {isNotSmall && (
            <>
              <Anchors />
              <Products />
            </>
          )}
          {isLarge && (
            <div id="large-cart-wrapper" className="cell shrink">
              <LargeCart />
            </div>
          )}
          {isNotLarge && <SmallCart />}
        </div>
        <div className="grid-x align-center alergens">
          <div className="small-12 medium-10 large-6 cell text-center">
            <p>
              Všechna zobrazovaná jídla mohou obsahovat alergeny. Pro informace
              nás kontaktujte na telefonu{" "}
              <a className="telephone" href="tel:606115119">
                606 115 119
              </a>{" "}
              nebo na našem{" "}
              <a href="https://www.facebook.com/pizzaburgercimelicedokrabice/">
                Facebooku
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
