import React from "react";

export default function RadioInput({ id, choices, action, checkout }) {
  return (
    <div className="grid-x grid-margin-x">
      {choices &&
        choices.map(choice => (
          <div className="medium-6 cell" key={choice.value}>
            <div className="input-field radio">
              <span
                className={`check ${
                  checkout[id] === choice.value ? "checked" : ""
                }`}
              ></span>
              <input
                type="radio"
                id={choice.value}
                name={id}
                value={choice.value}
                checked={checkout[id] === choice.value}
                onChange={action}
              />
              <label htmlFor={choice.value}>{choice.label}</label>
            </div>
          </div>
        ))}
    </div>
  );
}
