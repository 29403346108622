import React from "react";

export function ArrowLeft() {
  return (
    <svg
      width="22"
      height="10"
      viewBox="0 0 22 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 1L2 5M2 5L6 9M2 5L22 5" stroke="black" strokeWidth="2" />
    </svg>
  );
}

export function Loader() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="456.817px"
      height="456.817px"
      viewBox="0 0 456.817 456.817"
    >
      <g>
        <g>
          <path
            d="M109.641,324.332c-11.423,0-21.13,3.997-29.125,11.991c-7.992,8.001-11.991,17.706-11.991,29.129
        c0,11.424,3.996,21.129,11.991,29.13c7.998,7.994,17.705,11.991,29.125,11.991c11.231,0,20.889-3.997,28.98-11.991
        c8.088-7.991,12.132-17.706,12.132-29.13c0-11.423-4.043-21.121-12.132-29.129C130.529,328.336,120.872,324.332,109.641,324.332z"
          />
          <path
            d="M100.505,237.542c0-12.562-4.471-23.313-13.418-32.267c-8.946-8.946-19.702-13.418-32.264-13.418
        c-12.563,0-23.317,4.473-32.264,13.418c-8.945,8.947-13.417,19.701-13.417,32.267c0,12.56,4.471,23.309,13.417,32.258
        c8.947,8.949,19.701,13.422,32.264,13.422c12.562,0,23.318-4.473,32.264-13.422C96.034,260.857,100.505,250.102,100.505,237.542z"
          />
          <path
            d="M365.454,132.48c6.276,0,11.662-2.24,16.129-6.711c4.473-4.475,6.714-9.854,6.714-16.134
        c0-6.283-2.241-11.658-6.714-16.13c-4.47-4.475-9.853-6.711-16.129-6.711c-6.283,0-11.663,2.24-16.136,6.711
        c-4.47,4.473-6.707,9.847-6.707,16.13s2.237,11.659,6.707,16.134C353.791,130.244,359.171,132.48,365.454,132.48z"
          />
          <path
            d="M109.644,59.388c-13.897,0-25.745,4.902-35.548,14.703c-9.804,9.801-14.703,21.65-14.703,35.544
        c0,13.899,4.899,25.743,14.703,35.548c9.806,9.804,21.654,14.705,35.548,14.705s25.743-4.904,35.544-14.705
        c9.801-9.805,14.703-21.652,14.703-35.548c0-13.894-4.902-25.743-14.703-35.544C135.387,64.29,123.538,59.388,109.644,59.388z"
          />
          <path
            d="M439.684,218.125c-5.328-5.33-11.799-7.992-19.41-7.992c-7.618,0-14.089,2.662-19.417,7.992
        c-5.325,5.33-7.987,11.803-7.987,19.421c0,7.61,2.662,14.092,7.987,19.41c5.331,5.332,11.799,7.994,19.417,7.994
        c7.611,0,14.086-2.662,19.41-7.994c5.332-5.324,7.991-11.8,7.991-19.41C447.675,229.932,445.02,223.458,439.684,218.125z"
          />
          <path
            d="M365.454,333.473c-8.761,0-16.279,3.138-22.562,9.421c-6.276,6.276-9.418,13.798-9.418,22.559
        c0,8.754,3.142,16.276,9.418,22.56c6.283,6.282,13.802,9.417,22.562,9.417c8.754,0,16.272-3.141,22.555-9.417
        c6.283-6.283,9.422-13.802,9.422-22.56c0-8.761-3.139-16.275-9.422-22.559C381.727,336.61,374.208,333.473,365.454,333.473z"
          />
          <path
            d="M237.547,383.717c-10.088,0-18.702,3.576-25.844,10.715c-7.135,7.139-10.705,15.748-10.705,25.837
        s3.566,18.699,10.705,25.837c7.142,7.139,15.752,10.712,25.844,10.712c10.089,0,18.699-3.573,25.838-10.712
        c7.139-7.138,10.708-15.748,10.708-25.837s-3.569-18.698-10.708-25.837S247.636,383.717,237.547,383.717z"
          />
          <path
            d="M237.547,0c-15.225,0-28.174,5.327-38.834,15.986c-10.657,10.66-15.986,23.606-15.986,38.832
        c0,15.227,5.327,28.167,15.986,38.828c10.66,10.657,23.606,15.987,38.834,15.987c15.232,0,28.172-5.327,38.828-15.987
        c10.656-10.656,15.985-23.601,15.985-38.828c0-15.225-5.329-28.168-15.985-38.832C265.719,5.33,252.779,0,237.547,0z"
          />
        </g>
      </g>
    </svg>
  );
}

export function AngleRight() {
  return (
    <svg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L5 5L1 9" stroke="black" strokeWidth="2" />
    </svg>
  );
}

export function Pizza() {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 512.002 512.002"
      height="512"
      viewBox="0 0 512.002 512.002"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path d="m448.235 202.366c-1.779-.89-3.839-1.037-5.726-.407l-198.699 66.232 66.233-198.698c.629-1.888.482-3.947-.407-5.727-.89-1.779-2.45-3.132-4.337-3.761-23.712-7.901-48.39-11.907-73.347-11.907-61.957 0-120.205 24.127-164.015 67.937s-67.937 102.058-67.937 164.015 24.127 120.205 67.937 164.014c43.81 43.81 102.058 67.938 164.015 67.938s120.205-24.127 164.015-67.938c43.81-43.81 67.937-102.058 67.937-164.014 0-24.958-4.006-49.635-11.908-73.347-.629-1.888-1.982-3.447-3.761-4.337zm-62.875 231.092c-40.977 40.977-95.458 63.544-153.408 63.544s-112.431-22.567-153.408-63.544c-40.977-40.977-63.544-95.458-63.544-153.408s22.567-112.431 63.543-153.408c40.977-40.977 95.458-63.544 153.408-63.544 20.842 0 41.478 2.988 61.448 8.889l-5.401 16.203c-18.222-5.326-37.04-8.027-56.047-8.027-110.218 0-199.887 89.669-199.887 199.887s89.669 199.887 199.887 199.887c60.983 0 117.858-27.32 156.044-74.956 2.591-3.232 2.071-7.952-1.161-10.542-3.232-2.592-7.953-2.071-10.543 1.161-35.323 44.065-87.933 69.337-144.34 69.337-101.947 0-184.887-82.94-184.887-184.887s82.94-184.887 184.887-184.887c17.393 0 34.615 2.441 51.302 7.264l-58.417 175.251c-.898 2.695-.197 5.666 1.812 7.675s4.98 2.711 7.675 1.812l175.251-58.417c4.823 16.688 7.264 33.91 7.264 51.302 0 31.356-7.99 62.329-23.105 89.57-2.01 3.622-.703 8.188 2.919 10.197 1.153.64 2.401.943 3.632.943 2.637 0 5.195-1.394 6.565-3.863 16.348-29.462 24.989-62.951 24.989-96.848 0-19.007-2.701-37.825-8.028-56.048l16.203-5.401c5.901 19.97 8.889 40.605 8.889 61.449.001 57.951-22.566 112.432-63.542 153.409z" />
        </g>
        <g>
          <path d="m511.615 147.084c-22.958-68.9-77.798-123.74-146.697-146.698-3.929-1.308-8.176.814-9.486 4.744l-70.976 212.929c-.898 2.694-.197 5.666 1.812 7.675 1.43 1.43 3.346 2.197 5.304 2.197.793 0 1.594-.126 2.371-.385l182.509-60.836h.001c.001 0 .002 0 .003-.001l30.416-10.139c3.929-1.31 6.053-5.557 4.743-9.486zm-32.923 3.067c-13.527-36.074-36.992-67.193-68.209-90.338-3.328-2.468-8.025-1.77-10.491 1.558-2.467 3.327-1.77 8.024 1.558 10.491 28.729 21.301 50.359 49.896 62.901 83.037l-161.021 53.673 53.668-161.003c5.386 2.044 10.699 4.33 15.856 6.863 1.064.522 2.191.77 3.301.77 2.77 0 5.434-1.541 6.737-4.195 1.826-3.718.292-8.212-3.426-10.038-5.759-2.829-11.695-5.388-17.718-7.652l5.401-16.203c58.772 21.905 105.734 68.867 127.639 127.64z" />
        </g>
        <g>
          <path d="m191.355 199.889c0-3.347-.42-6.674-1.248-9.89-1.034-4.011-5.124-6.425-9.134-5.392-4.011 1.033-6.425 5.122-5.393 9.133.514 1.995.774 4.063.774 6.148 0 13.545-11.02 24.564-24.564 24.564s-24.565-11.02-24.565-24.564 11.02-24.565 24.565-24.565c2.09 0 4.164.262 6.163.778 4.009 1.037 8.101-1.376 9.136-5.388 1.035-4.011-1.377-8.101-5.388-9.136-3.223-.832-6.558-1.254-9.911-1.254-21.816 0-39.565 17.749-39.565 39.565s17.749 39.564 39.565 39.564c21.816.001 39.565-17.747 39.565-39.563z" />
        </g>
        <g>
          <path d="m376.758 135.76c0 12.976 10.557 23.532 23.532 23.532s23.532-10.557 23.532-23.532-10.557-23.532-23.532-23.532-23.532 10.556-23.532 23.532zm23.533-8.533c4.705 0 8.532 3.828 8.532 8.532 0 4.705-3.828 8.532-8.532 8.532s-8.532-3.827-8.532-8.532c-.001-4.704 3.827-8.532 8.532-8.532z" />
        </g>
        <g>
          <path d="m280.048 344.695c-17.396 0-31.548 14.153-31.548 31.549s14.152 31.548 31.548 31.548 31.548-14.152 31.548-31.548c.001-17.396-14.152-31.549-31.548-31.549zm0 48.097c-9.125 0-16.548-7.423-16.548-16.548s7.423-16.549 16.548-16.549 16.548 7.424 16.548 16.549c.001 9.124-7.423 16.548-16.548 16.548z" />
        </g>
        <g>
          <path d="m159.807 304.614c-17.396 0-31.548 14.153-31.548 31.548 0 17.396 14.152 31.549 31.548 31.549s31.548-14.153 31.548-31.549c0-17.395-14.152-31.548-31.548-31.548zm0 48.098c-9.125 0-16.548-7.424-16.548-16.549s7.423-16.548 16.548-16.548 16.548 7.423 16.548 16.548-7.423 16.549-16.548 16.549z" />
        </g>
        <g>
          <path d="m375.726 296.082c0-12.976-10.557-23.532-23.532-23.532s-23.532 10.557-23.532 23.532 10.557 23.532 23.532 23.532 23.532-10.556 23.532-23.532zm-23.533 8.532c-4.705 0-8.532-3.828-8.532-8.532s3.828-8.532 8.532-8.532 8.532 3.828 8.532 8.532-3.827 8.532-8.532 8.532z" />
        </g>
        <g>
          <path d="m207.903 392.792c-12.976 0-23.532 10.557-23.532 23.532s10.557 23.532 23.532 23.532 23.532-10.557 23.532-23.532-10.556-23.532-23.532-23.532zm0 32.064c-4.705 0-8.532-3.827-8.532-8.532 0-4.704 3.828-8.532 8.532-8.532s8.532 3.828 8.532 8.532c.001 4.705-3.827 8.532-8.532 8.532z" />
        </g>
        <g>
          <path d="m355.547 137.068c-3.705-1.852-8.21-.35-10.062 3.354l-8.016 16.032c-1.853 3.705-.351 8.21 3.354 10.063 1.077.538 2.221.793 3.348.793 2.751 0 5.4-1.52 6.714-4.148l8.016-16.032c1.853-3.705.351-8.21-3.354-10.062z" />
        </g>
        <g>
          <path d="m381.545 82.36-8.016-8.017c-2.929-2.929-7.678-2.929-10.606 0-2.929 2.929-2.929 7.678 0 10.606l8.016 8.017c1.464 1.464 3.384 2.196 5.303 2.196s3.839-.732 5.303-2.196c2.929-2.929 2.929-7.677 0-10.606z" />
        </g>
        <g>
          <path d="m196.533 142.468 16.032 8.016c1.077.538 2.221.793 3.348.793 2.751 0 5.4-1.52 6.714-4.148 1.853-3.705.351-8.209-3.354-10.062l-16.032-8.016c-3.705-1.853-8.21-.351-10.062 3.354-1.852 3.706-.351 8.21 3.354 10.063z" />
        </g>
        <g>
          <path d="m247.984 143.26c4.142 0 7.5-3.357 7.5-7.5v-8.016c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v8.016c0 4.142 3.358 7.5 7.5 7.5z" />
        </g>
        <g>
          <path d="m76.291 254.694 16.032 8.016c1.077.539 2.221.794 3.348.794 2.751 0 5.4-1.52 6.714-4.148 1.853-3.705.351-8.209-3.354-10.062l-16.032-8.017c-3.705-1.853-8.21-.351-10.062 3.354-1.853 3.706-.351 8.211 3.354 10.063z" />
        </g>
        <g>
          <path d="m128.258 272.034v8.016c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-8.016c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5z" />
        </g>
        <g>
          <path d="m216.436 199.889v8.016c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-8.016c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5z" />
        </g>
        <g>
          <path d="m181.142 258.715c-2.929-2.929-7.678-2.929-10.606 0-2.929 2.929-2.929 7.678 0 10.606l8.016 8.016c1.464 1.464 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197c2.929-2.929 2.929-7.677 0-10.606z" />
        </g>
        <g>
          <path d="m92.964 298.795c-2.929-2.929-7.678-2.929-10.606 0l-8.016 8.016c-2.929 2.929-2.929 7.678 0 10.606 1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197l8.016-8.016c2.93-2.929 2.93-7.677 0-10.606z" />
        </g>
        <g>
          <path d="m103.693 344.695h-8.016c-4.142 0-7.5 3.358-7.5 7.5 0 4.143 3.358 7.5 7.5 7.5h8.016c4.142 0 7.5-3.357 7.5-7.5 0-4.142-3.358-7.5-7.5-7.5z" />
        </g>
        <g>
          <path d="m234.665 314.828-8.016 8.016c-2.929 2.929-2.929 7.678 0 10.606 1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197l8.016-8.016c2.929-2.929 2.929-7.678 0-10.606-2.929-2.93-7.678-2.93-10.606 0z" />
        </g>
        <g>
          <path d="m207.903 360.727c-4.142 0-7.5 3.358-7.5 7.5 0 4.143 3.358 7.5 7.5 7.5h8.016c4.142 0 7.5-3.357 7.5-7.5 0-4.142-3.358-7.5-7.5-7.5z" />
        </g>
        <g>
          <path d="m151.791 392.792c-4.142 0-7.5 3.358-7.5 7.5v8.017c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-8.017c0-4.142-3.358-7.5-7.5-7.5z" />
        </g>
        <g>
          <path d="m272.032 424.856h-8.016c-4.142 0-7.5 3.358-7.5 7.5 0 4.143 3.358 7.5 7.5 7.5h8.016c4.142 0 7.5-3.357 7.5-7.5 0-4.141-3.358-7.5-7.5-7.5z" />
        </g>
        <g>
          <path d="m296.081 327.631c4.142 0 7.5-3.357 7.5-7.5v-16.032c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v16.032c0 4.142 3.357 7.5 7.5 7.5z" />
        </g>
        <g>
          <path d="m349.48 354.908c-2.929-2.929-7.678-2.929-10.606 0-2.929 2.929-2.929 7.678 0 10.606l8.016 8.017c1.464 1.464 3.384 2.196 5.303 2.196s3.839-.732 5.303-2.196c2.929-2.929 2.929-7.678 0-10.606z" />
        </g>
        <g>
          <path d="m384.258 328.663h-8.017c-4.142 0-7.5 3.358-7.5 7.5 0 4.143 3.358 7.5 7.5 7.5h8.017c4.142 0 7.5-3.357 7.5-7.5 0-4.142-3.358-7.5-7.5-7.5z" />
        </g>
        <g>
          <path d="m399.774 272.034v-8.016c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v8.016c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5z" />
        </g>
      </g>
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.47461"
        y="8.13184"
        width="8"
        height="1"
        transform="rotate(-45 2.47461 8.13184)"
        fill="black"
      />
      <rect
        x="8.13086"
        y="8.83887"
        width="8"
        height="1"
        transform="rotate(-135 8.13086 8.83887)"
        fill="black"
      />
    </svg>
  );
}

export function UserIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.88501 7.70724C8.94385 7.70724 9.86072 7.32749 10.6099 6.57822C11.359 5.82908 11.7388 4.91246 11.7388 3.8535C11.7388 2.79491 11.359 1.87817 10.6097 1.12878C9.86047 0.379759 8.94373 0 7.88501 0C6.82605 0 5.90942 0.379759 5.16028 1.1289C4.41113 1.87804 4.03125 2.79479 4.03125 3.8535C4.03125 4.91246 4.41113 5.8292 5.1604 6.57834C5.90967 7.32736 6.82642 7.70724 7.88501 7.70724Z"
        fill="#CF2A36"
      />
      <path
        d="M14.629 12.3031C14.6074 11.9913 14.5637 11.6512 14.4994 11.2921C14.4344 10.9303 14.3508 10.5882 14.2507 10.2756C14.1473 9.9525 14.0067 9.63341 13.8329 9.32763C13.6525 9.01025 13.4406 8.73388 13.2028 8.50647C12.9541 8.26855 12.6497 8.07727 12.2976 7.93774C11.9468 7.79895 11.558 7.72864 11.1421 7.72864C10.9788 7.72864 10.8208 7.79565 10.5157 7.99426C10.328 8.1167 10.1084 8.2583 9.86328 8.41491C9.65369 8.54846 9.36975 8.67358 9.01904 8.78686C8.67688 8.89758 8.32947 8.95373 7.98657 8.95373C7.64368 8.95373 7.29639 8.89758 6.95386 8.78686C6.60352 8.6737 6.31958 8.54858 6.11023 8.41504C5.86743 8.25989 5.64771 8.11828 5.45715 7.99414C5.15247 7.79553 4.99438 7.72852 4.83105 7.72852C4.41504 7.72852 4.02637 7.79895 3.67566 7.93787C3.32385 8.07715 3.01929 8.26843 2.77039 8.50659C2.53271 8.73413 2.32068 9.01037 2.1405 9.32763C1.9668 9.63341 1.82617 9.95238 1.72266 10.2757C1.62268 10.5884 1.53906 10.9303 1.47412 11.2921C1.40979 11.6507 1.36609 11.9909 1.34448 12.3034C1.32324 12.6096 1.3125 12.9273 1.3125 13.2483C1.3125 14.0835 1.578 14.7596 2.10156 15.2583C2.61865 15.7503 3.30286 16 4.13489 16H11.839C12.671 16 13.355 15.7505 13.8722 15.2583C14.3959 14.76 14.6614 14.0837 14.6614 13.2481C14.6613 12.9258 14.6504 12.6078 14.629 12.3031Z"
        fill="#CF2A36"
      />
    </svg>
  );
}

export function FacebookIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 6.875V4.375C11.25 3.685 11.81 3.125 12.5 3.125H13.75V0H11.25C9.17875 0 7.5 1.67875 7.5 3.75V6.875H5V10H7.5V20H11.25V10H13.75L15 6.875H11.25Z"
        fill="#CF2A36"
      />
    </svg>
  );
}
