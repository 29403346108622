import React, { useState, useEffect } from "react";
import { functions } from "../firebase";

export default function AdressInput({ setCheckout, filledAdress }) {
  const [adresses, setAdresses] = useState([]);
  const [state, setState] = useState({
    value: "",
    selected: false,
    updated: false,
  });
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getAdresses = functions.httpsCallable("getAdresses");
    getAdresses().then((result) => {
      setAdresses(result.data);
    });
  }, []);

  function handleChange(e) {
    setState({ value: e.target.value });
    setCheckout((prevState) => ({
      ...prevState,
      adress: null,
    }));
  }

  useEffect(() => {
    let matches = [];
    if (!state.selected && state.value.length > 0 && adresses.length > 0) {
      matches = adresses.filter((item) =>
        item.fullAdress
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            state.value
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
      );
    }

    const reducedMatches = matches.slice(0, 8);
    setResults(reducedMatches);
  }, [state]);

  useEffect(() => {
    if (filledAdress !== null) {
      setState({
        value: filledAdress,
        selected: true,
      });
    }
  }, []);

  useEffect(() => {
    if (state.value.length > 1 && (results.length < 1) & !state.selected) {
      setError(
        "Adresa není v Čimelicích nebo je v ní chyba. Musíte vybrat adresu z nabídky."
      );
    } else {
      setError(null);
    }
  }, [state, results]);

  function setAdress(result) {
    setCheckout((prevState) => ({
      ...prevState,
      adress: result.fullAdress,
    }));
    setState({
      value: result.fullAdress,
      selected: true,
    });
  }

  return (
    <div className="input-field adress">
      <div className="grid-x grid-margin-x">
        <div className="cell auto naseptavac-wrapper">
          <input
            className={`${error ? "invalid" : ""} ${
              state.selected ? "valid" : ""
            }`}
            type="text"
            id="adress"
            name="adress"
            placeholder="Např. Čimelice 10"
            value={state.value}
            onChange={handleChange}
            autocomplete="off"
          />
          {results.length > 0 && (
            <div className="naseptavac">
              {results.map((result) => (
                <div
                  key={result.fullAdress}
                  className="naseptavac-item"
                  onClick={() => {
                    setAdress(result);
                  }}
                >
                  {result.fullAdress}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  );
}
