import React from "react";
import map from "../map.svg";
import { FacebookIcon } from "./svg";

export default function Footer() {
  return (
    <>
      <div className="steps">
        <div className="grid-container">
          <div className="grid-x">
            <div className="small-12 medium-4 cell step">
              <div className="grid-x align-middle align-center">
                <div className="cell shrink">
                  <span className="step-number">1.</span>
                </div>
                <div className="cell shrink">
                  <span className="step-text">Vyberte si své jídlo</span>
                </div>
              </div>
            </div>
            <div className="small-12 medium-4 cell step">
              <div className="grid-x align-middle align-center">
                <div className="cell shrink">
                  <span className="step-number">2.</span>
                </div>
                <div className="cell shrink">
                  <span className="step-text">
                    Zadejte adresu, na kterou chcete objednávku dovézt
                  </span>
                </div>
              </div>
            </div>
            <div className="small-12 medium-4 cell step">
              <div className="grid-x align-middle align-center">
                <div className="cell shrink">
                  <span className="step-number">3.</span>
                </div>
                <div className="cell shrink">
                  <span className="step-text">Vyčkejte na příjezd kurýra</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container footer-info">
        <div className="grid-x align-middle">
          <div className="cell small-12 medium-shrink">
            <span className="footer-headline">Informace k rozvozu</span>
            <ul className="bulleted">
              <li>cena rozvozu zdarma</li>
              <li>minimální objednávka 150 Kč</li>
              <li>přivezeme do 90 min dle aktuálního vytížení</li>
              <li>obal dle velikosti jídla - 5 Kč, 10 Kč, 20 Kč</li>
              <li>
                lze platit hotově, kartou nebo v eurech dle aktuálního kurzu při
                převzetí
              </li>
              <li>rozvoz po Čimelicích</li>
              <li>poslední objednávka 15 min před koncem otevírací doby</li>
            </ul>
          </div>
          <div className="cell small-12 medium-auto"></div>
          <div className="cell small-12 medium-shrink">
            <span className="footer-headline">Neváhejte nás kontaktovat</span>
            <div className="footer-contact">
              <a href="tel:606115119">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.8889 13.8889C17.5056 13.8889 16.1667 13.6667 14.9222 13.2556C14.5389 13.1333 14.1 13.2222 13.7945 13.5278L11.3501 15.9778C8.20005 14.3778 5.62781 11.8056 4.02781 8.66115L6.47224 6.20557C6.77781 5.9 6.86667 5.46115 6.74448 5.07781C6.33339 3.83339 6.11115 2.49448 6.11115 1.11115C6.11109 0.494427 5.61667 0 5 0H1.11109C0.5 0 0 0.494427 0 1.11109C0 11.5444 8.45557 20 18.8889 20C19.5056 20 20 19.5056 20 18.8889V15C20 14.3833 19.5056 13.8889 18.8889 13.8889Z"
                    fill="#CF2A36"
                  />
                </svg>
                606 115 119
              </a>
            </div>
            <div className="footer-contact">
              <a href="https://www.facebook.com/pizzaburgercimelicedokrabice/">
                <FacebookIcon />
                Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container copyright">
        <div className="grid-x align-middle">
          <div className="cell small-12 medium-6">
            <p>
              Podle zákona o evidenci tržeb je prodávající povinen vystavit
              kupujícímu účtenku. Zároveň je povinen zaevidovat přijatou tržbu u
              správce daně online; v případě technického výpadku pak nejpozději
              do 48 hodin.
            </p>
          </div>
          <div className="cell small-12 medium-6 text-right">
            <a href="http://simpled.cz">
              Made by
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0)">
                  <path
                    d="M21.2406 38.9854L12.7132 38.9777L0.015625 26.0209L12.7732 13.0412H21.2332L8.46063 26.0183L21.2406 38.9854Z"
                    fill="#ADADAD"
                  />
                  <path
                    d="M17.7635 25.957L30.5473 12.9659L17.7598 0.00143623L26.1598 -0.00624084L38.9998 12.9811L26.1598 25.9531L17.7635 25.957Z"
                    fill="#ADADAD"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect
                      width="39"
                      height="39"
                      transform="matrix(1 0 0 -1 0 39)"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
