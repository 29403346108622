import React, { useEffect } from "react";
import { AngleRight } from "./svg";
import { useMenuValue } from "../contexts";
import { stickyPanel, smoothScroll, setActiveLink } from "../utils/";
import { isNowOpen, isDayToday } from "../utils/openHours";

export default function Anchors() {
  const { menu, dailyMenu, settings } = useMenuValue();

  useEffect(() => {
    window.addEventListener("resize", handleListeners);
    window.addEventListener("scroll", handleListeners);
    window.addEventListener("scroll", function () {
      setActiveLink(".anchors li a");
    });

    return () => {
      window.removeEventListener("resize", handleListeners);
      window.removeEventListener("scroll", handleListeners);
      window.removeEventListener("scroll", function () {
        setActiveLink(".anchors li a");
      });
    };
  }, [menu, dailyMenu]);

  function handleListeners() {
    stickyPanel("ul.anchors", "div#anchors-wrapper", "div.product-list");
  }

  function animate(e) {
    e.preventDefault();
    let link = e.target;
    let destId = link.getAttribute("href");
    let dest = destId.replace("#", "");
    smoothScroll(dest);
  }

  function dailyMenuOpen() {
    if (settings && dailyMenu) {
      const dailyMenuOpen = isNowOpen(settings.dailymenudelivery[0].dayofweek);
      const menuDateSource = new Date(dailyMenu[0].ACF.datum_daily_menu);
      const menuDate = isDayToday(menuDateSource);
      return dailyMenuOpen && menuDate;
    } else {
      return false;
    }
  }

  return (
    <div id="anchors-wrapper" className="cell shrink">
      <ul className="anchors">
        {dailyMenu.length > 0 && dailyMenuOpen() && (
          <li key={dailyMenu[0].slug}>
            <a href={`#${dailyMenu[0].slug}`} onClick={animate}>
              {dailyMenu[0].title.rendered}
              <AngleRight />
            </a>
          </li>
        )}
        {menu &&
          menu.map((item) => (
            <li key={item.slug}>
              <a href={`#${item.slug}`} onClick={animate}>
                {item.title.rendered}
                <AngleRight />
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
}
