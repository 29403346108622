import React, { useState } from "react";
import SingleProduct from "./SingleProduct";

export default function ProductsSection({ item, tabs }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="products-section" id={item.slug}>
      <span
        className={`section-title ${tabs ? (expanded ? "expanded" : "") : ""}`}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {item.title.rendered}
        {tabs && (
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9 1L5 5L1 1" stroke="black" strokeWidth="2" />
          </svg>
        )}
      </span>
      {tabs ? (
        <div className={`section-content ${expanded ? "expanded" : ""}`}>
          {item.ACF.jidlo &&
            item.ACF.jidlo.map(jidlo => (
              <SingleProduct
                cat={item.slug}
                catName={item.title.rendered}
                jidlo={jidlo}
                balne={
                  jidlo.balne === "" || jidlo.balne === false
                    ? item.ACF.balne
                    : jidlo.balne
                }
                key={jidlo.jidlo_name}
              />
            ))}
        </div>
      ) : (
        <div className="section-content">
          {item.ACF.jidlo &&
            item.ACF.jidlo.map(jidlo => (
              <SingleProduct
                cat={item.slug}
                catName={item.title.rendered}
                jidlo={jidlo}
                balne={
                  jidlo.balne === "" || jidlo.balne === false
                    ? item.ACF.balne
                    : jidlo.balne
                }
                key={jidlo.jidlo_name}
              />
            ))}
        </div>
      )}
    </div>
  );
}
