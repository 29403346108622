import React from "react";
import Layout from "./components/Layout";
import {
  OrderStateProvider,
  MenuProvider,
  CartProvider,
  SelectedProductProvider,
  OrderTotalProvider,
  CheckoutProvider,
  AdressProvider,
} from "./contexts";

function App() {
  return (
    <div className="App">
      <OrderStateProvider>
        <MenuProvider>
          <CartProvider>
            <OrderTotalProvider>
              <CheckoutProvider>
                <SelectedProductProvider>
                  <AdressProvider>
                    <Layout />
                  </AdressProvider>
                </SelectedProductProvider>
              </CheckoutProvider>
            </OrderTotalProvider>
          </CartProvider>
        </MenuProvider>
      </OrderStateProvider>
    </div>
  );
}

export default App;
