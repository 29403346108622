import React, { createContext, useContext, useState } from "react";

export const OrderTotalContext = createContext();
export const OrderTotalProvider = ({ children }) => {
  const [total, setTotal] = useState({
    orderPrice: 0,
    packaging: 0,
    delivery: 30,
    orderTotal: 0,
    surcharge: 0,
    itemCount: 0
  });

  return (
    <OrderTotalContext.Provider value={{ total, setTotal }}>
      {children}
    </OrderTotalContext.Provider>
  );
};

export const useOrderTotal = () => useContext(OrderTotalContext);
