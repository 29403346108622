import React, { createContext, useContext, useState } from "react";

export const CheckoutContext = createContext();
export const CheckoutProvider = ({ children }) => {
  const [checkout, setCheckout] = useState({
    name: "",
    phone: "",
    email: "",
    shipping: "delivery",
    adress: null,
    payment: "cash",
    note: ""
  });

  return (
    <CheckoutContext.Provider value={{ checkout, setCheckout }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckout = () => useContext(CheckoutContext);
