import { isEqual } from "lodash";

export function stickyPanel(stickyEl, stickyWrapper, stickTo) {
  let sticky = document.querySelector(stickyEl);
  let wrapper = document.querySelector(stickyWrapper);
  let anchor = document.querySelector(stickTo);
  let wrapperLeft = wrapper.offsetLeft;
  let anchorTop = anchor.offsetTop;
  let stickyHeight = sticky.offsetHeight;
  let anchorHeight = anchor.offsetHeight;
  let fromTop = window.scrollY;

  if (
    fromTop >= anchorTop - 20 &&
    anchorHeight + anchorTop - stickyHeight >= fromTop
  ) {
    sticky.setAttribute(
      "style",
      `position: fixed; top: 20px; left: ${wrapperLeft}px;`
    );
    wrapper.setAttribute("style", "position:relative;");
  } else if (
    anchorHeight !== 0 &&
    anchorHeight + anchorTop - stickyHeight <= fromTop
  ) {
    sticky.setAttribute("style", `position: absolute; bottom: 0px; left: 0px;`);
  } else {
    sticky.removeAttribute("style");
    wrapper.removeAttribute("style");
  }
}

export function smoothScroll(anchor) {
  const id = anchor;
  const el = document.getElementById(id);
  const y = el.getBoundingClientRect().top + window.pageYOffset;
  const offset = -16;
  window.scrollTo({ top: y + offset, behavior: "smooth" });
}

export function setActiveLink(el) {
  let mainNavLinks = document.querySelectorAll(el);
  let fromTop = window.scrollY;
  mainNavLinks.forEach((link) => {
    let section = document.querySelector(link.hash);
    if (
      section.offsetTop - 16 <= fromTop &&
      section.offsetTop + section.offsetHeight > fromTop
    ) {
      link.classList.add("current");
      link.classList.remove("active");
    } else {
      link.classList.remove("current");
    }
  });
}

export function makeSlug(string) {
  const slug = string
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

  return slug;
}

export function arraysMatch(arr1, arr2) {
  if (arr1.length === 0) return false;
  console.log(arr1.legth);
  if (arr2.length === 0) return false;
  console.log(arr2.legth);
  if (arr1.length !== arr2.length) return false;
  let i = 0;
  for (let el of arr1) {
    if (el !== arr2[i]) {
      return false;
    } else {
      i++;
    }
  }
}

export function qtyPlus(item, state, func) {
  const productToUpdate = state.filter(
    (el) =>
      el.name === item.name &&
      el.cat === item.cat &&
      el.price === item.price &&
      el.additives.length === item.additives.length &&
      isEqual(el.additives, item.additives)
  );
  if (productToUpdate.length > 0) {
    let idx = 0;
    for (let el of state) {
      if (
        el.name === item.name &&
        el.cat === item.cat &&
        el.price === item.price &&
        el.additives.length === item.additives.length &&
        isEqual(el.additives, item.additives)
      ) {
        break;
      } else {
        idx++;
      }
    }
    let newCart = [...state];
    newCart[idx].qty = productToUpdate[0].qty + 1;
    func(newCart);
  }
}

export function qtyMinus(item, state, func) {
  const productToUpdate = state.filter(
    (el) =>
      el.name === item.name &&
      el.cat === item.cat &&
      el.price === item.price &&
      el.additives.length === item.additives.length &&
      isEqual(el.additives, item.additives)
  );
  if (productToUpdate.length > 0) {
    let idx = 0;
    for (let el of state) {
      if (
        el.name === item.name &&
        el.cat === item.cat &&
        el.price === item.price &&
        el.additives.length === item.additives.length &&
        isEqual(el.additives, item.additives)
      ) {
        break;
      } else {
        idx++;
      }
    }
    let newCart = [...state];
    if (newCart[idx].qty > 1) {
      newCart[idx].qty = productToUpdate[0].qty - 1;
      func(newCart);
    } else {
      const reducedCart = state.filter(
        (el) =>
          el.name !== item.name ||
          el.cat !== item.cat ||
          el.price !== item.price ||
          el.additives.length !== item.additives.length ||
          !isEqual(el.additives, item.additives)
      );
      func(reducedCart);
      localStorage.removeItem("cart");
    }
  }
}

export function calculateItemPrice(item) {
  let additivesPrice = 0;
  if (item.additives.length > 0) {
    additivesPrice = item.additives.reduce((acc, el) => {
      acc += parseInt(el.price);
      return acc;
    }, 0);
  }

  const itemPrice = parseInt(item.price) + additivesPrice;

  return itemPrice;
}

export function getCartCount(cart) {
  let products = 0;
  if (cart.length > 0) {
    products = cart.reduce((acc, el) => {
      acc += parseInt(el.qty);
      return acc;
    }, 0);
  }
  return products;
}

export function calculatePackaging(cart) {
  let packaging = 0;
  if (cart.length > 0) {
    packaging = cart.reduce((acc, el) => {
      acc += parseInt(el.balne) * parseInt(el.qty);
      return acc;
    }, 0);
  }
  return packaging;
}

export function calculateOrderPrice(cart) {
  let orderPrice = 0;
  if (cart.length > 0) {
    orderPrice = cart.reduce((acc, el) => {
      acc += calculateItemPrice(el) * parseInt(el.qty);
      return acc;
    }, 0);
  }
  return orderPrice;
}

export function calculateDelivery(cart, checkout) {
  let delivery = 0;
  if (cart.length > 0) {
    const orderPrice = calculateOrderPrice(cart) + calculatePackaging(cart);
    if (orderPrice >= 150) {
      delivery = 0;
    }
    if (checkout.shipping === "pickup") {
      delivery = 0;
    }
  }

  return delivery;
}

export function calculateSurcharge(cart, checkout) {
  let surcharge = 0;
  if (cart.length > 0) {
    const orderPrice = calculateOrderPrice(cart) + calculatePackaging(cart);
    if (orderPrice < 150) {
      surcharge = 150 - orderPrice;
      console.log(surcharge);
    }
    if (checkout.shipping === "pickup") {
      surcharge = 0;
    }
  }

  return surcharge;
}

export function calculateCartHeight(cart) {
  const vHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  if (cart.length >= 2) {
    const cartWrapper = document.querySelector("#cart");
    const cartContent = document.querySelector(".cart-content");
    const cartItems = document.querySelectorAll(".cart-item");
    const calculateCartItemsHeight = () => {
      let itemsHeight = 0;
      cartItems.forEach((el) => {
        itemsHeight = itemsHeight + el.offsetHeight;
      });
      return itemsHeight;
    };
    const cartItemsHeight = calculateCartItemsHeight();
    const cartContentHeight = cartContent.offsetHeight;
    const cartHeight = cartWrapper.offsetHeight;
    const heightToSet = vHeight - (cartHeight - cartContentHeight);

    if (
      cartWrapper.classList.contains("sticky-cart") ||
      cartWrapper.classList.contains("cart-panel")
    ) {
      if (cartHeight + 40 >= vHeight) {
        cartContent.setAttribute(
          "style",
          `height: ${heightToSet - 40}px; overflow-y: scroll`
        );
      }
      if (
        cartItemsHeight + 1 < cartContentHeight &&
        cartContent.hasAttribute("style")
      ) {
        cartContent.removeAttribute("style");
      }
    } else {
      if (cartHeight > vHeight) {
        console.log("huuu");
        cartContent.setAttribute(
          "style",
          `height: ${heightToSet - 31}px; overflow-y: scroll`
        );
      }
      if (
        cartItemsHeight + 1 < cartContentHeight &&
        cartContent.hasAttribute("style")
      ) {
        cartContent.removeAttribute("style");
      }
    }
  }
}
