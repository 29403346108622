import React, { useEffect, useState } from "react";
import Cart from "./Cart";
import EmptyCart from "./EmptyCart";
import Button from "./Button";
import SurchargeConfirm from "./SurchargeConfirm";
import { isNowOpen } from "../utils/openHours";
import { stickyPanel } from "../utils";
import { firebase } from "../firebase";
import {
  useCart,
  useMenuValue,
  useOrderState,
  useOrderTotal,
} from "../contexts";

export default function LargeCart() {
  const [showSurchargeConfirm, setShowSurchargeConfirm] = useState(false);
  const { cart, setCart } = useCart();
  const { menu, settings } = useMenuValue();
  const { setOrderState } = useOrderState();
  const { total } = useOrderTotal();

  useEffect(() => {
    window.addEventListener("resize", handleListeners);
    window.addEventListener("scroll", handleListeners);

    return () => {
      window.removeEventListener("resize", handleListeners);
      window.removeEventListener("scroll", handleListeners);
    };
  }, [menu, cart]);

  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    } else if (cart.length === 0) {
      const storedCart = JSON.parse(localStorage.getItem("cart"));
      if (storedCart && storedCart.length > 0) {
        setCart(storedCart);
      }
    }
  }, [cart]);

  function handleListeners() {
    stickyPanel(
      "div.sticky-cart",
      "div#large-cart-wrapper",
      "div.product-list"
    );
  }

  function isOpen() {
    if (settings) {
      const permanentMenuOpen = isNowOpen(
        settings.permanentmenudelivery[0].dayofweek
      );
      return permanentMenuOpen;
    }
  }

  function nextStep(e) {
    e.preventDefault();
    if (total.surcharge > 0) {
      setShowSurchargeConfirm(true);
    } else {
      setOrderState("checkout");
      firebase.analytics().logEvent("begin_checkout", {
        currency: "CZK",
        value: total.orderTotal,
      });
      window.scrollTo(0, 0);
    }
  }

  return (
    <>
      <div className="sticky-cart" id="cart">
        <span className="cart-headline">Vaše objednávka</span>
        {cart.length >= 1 ? (
          <Cart
            cart={cart}
            setCart={setCart}
            controls={true}
            heightControl={true}
          />
        ) : (
          <EmptyCart />
        )}
        <Button
          classes="cta full"
          disabled={cart.length < 1 || !isOpen()}
          value={
            isOpen() ? "Pokračovat v objednávce" : "Nyní si nelze objednat"
          }
          action={nextStep}
        />
      </div>
      {showSurchargeConfirm && (
        <SurchargeConfirm setShowSurchargeConfirm={setShowSurchargeConfirm} />
      )}
    </>
  );
}
