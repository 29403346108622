import React from "react";
import { CloseIcon } from "./svg";

export default function Close({ label, action, classes }) {
  return (
    <span className={`close-btn ${classes}`} onClick={action}>
      {label}
      <CloseIcon />
    </span>
  );
}
