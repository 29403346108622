import React from "react";

export default function Checkbox({ name, label, action, checked }) {
  return (
    <div className="input-field checkbox">
      <input type="checkbox" id={name} onChange={action} checked={checked} />
      <span className={`checkbox ${checked ? "checked" : ""}`} onClick={action}>
        <svg
          width="13"
          height="10"
          viewBox="0 0 13 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 4.5L4.66667 8L12 1" stroke="white" strokeWidth="2" />
        </svg>
      </span>
      <label htmlFor={name}>{label}</label>
    </div>
  );
}
