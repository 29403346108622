import React, { useEffect, useState } from "react";
import ProductsSection from "./ProductsSection";
import Modal from "./Modal";
import { useMenuValue, useSelectedProduct } from "../contexts";
import IngredienceSelect from "./IngredienceSelect";
import { isNowOpen, isDayToday } from "../utils/openHours";
import { Loader } from "./svg";

export default function Products({ tabs }) {
  const { menu, dailyMenu, settings } = useMenuValue();
  const { selectedProduct, setSelectedProduct } = useSelectedProduct();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (menu.length > 0) {
      setLoading(false);
    }
  }, [menu]);

  function dailyMenuOpen() {
    if (settings && dailyMenu) {
      const dailyMenuOpen = isNowOpen(settings.dailymenudelivery[0].dayofweek);
      const menuDateSource = new Date(dailyMenu[0].ACF.datum_daily_menu);
      const menuDate = isDayToday(menuDateSource);
      return dailyMenuOpen && menuDate;
    } else {
      return false;
    }
  }

  return (
    <>
      {loading && (
        <div className="cell auto loading">
          <Loader />
        </div>
      )}
      <div className="cell auto product-list">
        {dailyMenu.length > 0 && dailyMenuOpen() && (
          <ProductsSection item={dailyMenu[0]} tabs={tabs} />
        )}
        {menu &&
          menu.map((item) => (
            <ProductsSection item={item} key={item.id} tabs={tabs} />
          ))}
      </div>
      {selectedProduct && (
        <Modal
          closeAction={() => {
            setSelectedProduct();
          }}
        >
          <IngredienceSelect
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        </Modal>
      )}
    </>
  );
}
