import React, { createContext, useContext, useState } from "react";

export const SelectedProductContext = createContext();
export const SelectedProductProvider = ({ children }) => {
  const [selectedProduct, setSelectedProduct] = useState();

  return (
    <SelectedProductContext.Provider
      value={{ selectedProduct, setSelectedProduct }}
    >
      {children}
    </SelectedProductContext.Provider>
  );
};

export const useSelectedProduct = () => useContext(SelectedProductContext);
