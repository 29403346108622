import React, { useEffect } from "react";

export default function Modal({ children, closeAction, size }) {
  useEffect(() => {
    document.querySelector("body").classList.add("modal-open");
    return function cleanup() {
      document.querySelector("body").classList.remove("modal-open");
    };
  }, []);
  return (
    <div className={`modal ${size && size}`}>
      <div className="modal-overlay" onClick={closeAction}></div>
      <div className="modal-panel">{children}</div>
    </div>
  );
}
