import React, { useState, useEffect } from "react";
import Checkbox from "./Checkbox";

export default function IngredienceItem({
  item,
  idx,
  selectedProduct,
  setSelectedProduct
}) {
  const [isChecked, setIsChecked] = useState(false);
  const add = { name: item.jidlo_name, price: item.jidlo_cena, idx: idx };

  useEffect(() => {
    if (isChecked) {
      setSelectedProduct(prevState => ({
        ...prevState,
        additives: [...prevState.additives, add]
      }));
    } else {
      let currentAdds = selectedProduct.additives;
      const newAdds = currentAdds.filter(i => i.idx !== add.idx);
      setSelectedProduct(prevState => ({
        ...prevState,
        additives: newAdds
      }));
    }
  }, [isChecked]);

  return (
    <div className="medium-6 cell ingredience-item">
      <div className={`grid-x align-justify ${isChecked ? "checked" : ""}`}>
        <div className="cell shrink">
          <Checkbox
            label={item.jidlo_name}
            name={item.jidlo_name}
            checked={isChecked}
            action={() => {
              setIsChecked(!isChecked);
            }}
          />
        </div>
        <div className="cell shrink">{item.jidlo_cena} Kč</div>
      </div>
    </div>
  );
}
