import React, { useState, useEffect } from "react";
import CheckoutCart from "./CheckoutCart";
import CheckoutPanel from "./CheckoutPanel";
import TextInput from "./TextInput";
import RadioInput from "./RadioInput";
import Button from "./Button";
import AdressInput from "./AdressInput";
import { ArrowLeft, Loader } from "./svg";
import { firebase } from "../firebase";
import { functions } from "../firebase";
import { useMediaQuery } from "react-responsive";
import {
  useOrderState,
  useCheckout,
  useCart,
  useOrderTotal,
} from "../contexts";
import { stickyPanel } from "../utils";

export default function Checkout() {
  const isNotSmall = useMediaQuery({ query: "(min-width: 641px)" });
  const isSmall = useMediaQuery({ query: "(max-width: 640px)" });
  const { setOrderState } = useOrderState();
  const { checkout, setCheckout } = useCheckout();
  const { cart } = useCart();
  const { total } = useOrderTotal();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    name: null,
    phone: null,
    email: null,
    adress: null,
  });

  useEffect(() => {
    window.addEventListener("resize", handleListeners);
    window.addEventListener("scroll", handleListeners);

    return () => {
      window.removeEventListener("resize", handleListeners);
      window.removeEventListener("scroll", handleListeners);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("checkout", JSON.stringify(checkout));
  }, [checkout]);

  useEffect(() => {
    if (checkout.adress !== null) {
      setError((prevState) => ({
        ...prevState,
        adress: false,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        adress: null,
      }));
    }
    if (checkout.name !== "") {
      validateName();
    }
    if (checkout.phone !== "") {
      validatePhone();
    }
    if (checkout.email !== "") {
      validateEmail();
    }
    if (checkout.shipping === "pickup") {
      setError((prevState) => ({
        ...prevState,
        adress: false,
      }));
    }
  }, [checkout]);

  function handleListeners() {
    if (isNotSmall) {
      stickyPanel(
        "div.sticky-cart",
        "div#large-cart-wrapper",
        "div.checkout-fields"
      );
    }
  }

  function validateName() {
    let error;
    if (checkout.name.length < 1) {
      error = "Vyplnění jména je povinné.";
    } else if (checkout.name.length < 5) {
      error = "Jméno by mělo mít alespoň 5 znaků";
    } else {
      error = false;
    }
    setError((prevState) => ({
      ...prevState,
      name: error,
    }));
  }

  function validatePhone() {
    let error;
    if (checkout.phone.length < 1) {
      error = "Vyplnění telefonu je povinné.";
    } else if (checkout.phone.length !== 13) {
      error = "Telefon není vyplněn správně";
    } else {
      error = false;
    }
    setError((prevState) => ({
      ...prevState,
      phone: error,
    }));
  }

  function validateEmail() {
    let error;
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (checkout.email.length < 1) {
      error = "Vyplnění emailu je povinné.";
    } else if (!reg.test(String(checkout.email).toLowerCase())) {
      error = "Email není vyplněn správně.";
    } else {
      error = false;
    }
    setError((prevState) => ({
      ...prevState,
      email: error,
    }));
  }

  function handleChange(e) {
    if (e.target.name === "phone") {
      const v = e.target.value;
      let p = ``;
      if (v > 0 && v[0] !== "+") {
        p = "+420" + v;
      } else {
        p = v;
      }

      setCheckout((prevState) => ({
        ...prevState,
        phone: p,
      }));
    } else {
      const { name, value } = e.target;

      setCheckout((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }

  function confirmOrder(e) {
    e.preventDefault();
    setLoading(true);
    const createdAt = Date.now();
    const order = {
      customer: checkout,
      cart: cart,
      orderTotal: total,
      read: false,
      timestamp: createdAt,
    };
    const addOrder = functions.httpsCallable("addOrder");
    addOrder(order).then(() => {
      firebase.analytics().logEvent("ecommerce_purchase", {
        currency: "CZK",
        value: total.orderTotal + total.surcharge,
        shipping: total.delivery,
      });
      setLoading(false);
      setOrderState("thankyou");
      window.scrollTo(0, 0);
      localStorage.clear();
    });
  }

  return (
    <>
      <div className="grid-container mb-3">
        <div className="grid-x grid-margin-x">
          <div className="cell shrink">
            <span
              className="back-link action"
              onClick={() => {
                setOrderState("order");
              }}
            >
              <ArrowLeft />
              Zpět k výběru jídla
            </span>
          </div>
        </div>
      </div>
      <div className="grid-container">
        <div className="grid-x grid-margin-x align-center">
          {isNotSmall && (
            <>
              <div id="large-cart-wrapper" className="cell shrink">
                <CheckoutCart />
              </div>
            </>
          )}
          <div className="cell auto checkout-fields">
            <CheckoutPanel
              icon="user"
              label="Kontaktní údaje"
              fields={[checkout.name, checkout.phone, checkout.email]}
            >
              <div className="grid-x grid-margin-x">
                <div className="medium-6 cell">
                  <TextInput
                    type="text"
                    label="Jméno"
                    placeholder="Jméno"
                    required={true}
                    id="name"
                    value={checkout.name}
                    action={handleChange}
                    error={error.name}
                    validation={validateName}
                  />
                </div>
                <div className="medium-6 cell">
                  <TextInput
                    type="telephone"
                    label="Telefon"
                    placeholder="Telefon"
                    required={true}
                    id="phone"
                    error={error.phone}
                    value={checkout.phone}
                    action={handleChange}
                    validation={validatePhone}
                  />
                </div>
                <div className="medium-6 cell">
                  <TextInput
                    type="email"
                    label="Email"
                    placeholder="Email"
                    required={true}
                    id="email"
                    error={error.email}
                    value={checkout.email}
                    action={handleChange}
                    validation={validateEmail}
                  />
                </div>
              </div>
            </CheckoutPanel>
            <CheckoutPanel
              icon="delivery"
              label="Způsob doručení"
              fields={[checkout.shipping]}
              func={function (item) {
                if (item === "delivery") {
                  return "Doručit kurýrem";
                } else {
                  return "Vyzvednout v restauraci";
                }
              }}
            >
              <RadioInput
                id="shipping"
                choices={[
                  { value: "delivery", label: "Doručit kurýrem" },
                  { value: "pickup", label: "Vyzvednout v restauraci" },
                ]}
                checkout={checkout}
                action={handleChange}
              />
            </CheckoutPanel>
            {checkout.shipping === "delivery" && (
              <CheckoutPanel
                icon="adress"
                label="Adresa doručení"
                fields={[checkout.adress]}
              >
                <AdressInput
                  value={checkout.adress}
                  error={error.adress}
                  setCheckout={setCheckout}
                  filledAdress={checkout.adress}
                />
              </CheckoutPanel>
            )}
            {/* <CheckoutPanel
              icon="payment"
              label="Způsob platby"
              fields={[checkout.payment]}
              func={function (item) {
                if (item === "cash") {
                  return "Platba hotově při převzetí";
                } else {
                  return "Platba kartou při převzetí";
                }
              }}
            >
              <RadioInput
                id="payment"
                choices={[
                  { value: "cash", label: "Hotově při převzetí" },
                  { value: "card", label: "Kartou při převzetí" },
                ]}
                checkout={checkout}
                action={handleChange}
              />
            </CheckoutPanel> */}
            <div className="input-field textarea mb-3 s-mb-0">
              <label htmlFor="note">Poznámka k objednávce</label>
              <textarea
                name="note"
                id="note"
                className="mb-3"
                value={checkout.note}
                onChange={handleChange}
                placeholder="Např. mohu platit pouze bankovkou v hodnotě 5000 Kč nebo chci objednávku doručit později"
              />
            </div>
            {isSmall && <CheckoutCart heightControl={true} />}
            {error.name !== false ||
            error.phone !== false ||
            error.email !== false ||
            error.adress !== false ? (
              <div className="grid-x checkout-error mb-3 align-middle">
                <div className="cell shrink">
                  <span className="error-sign">!</span>
                </div>
                <div className="cell auto">
                  <p>
                    Pro dokončení objednávky je potřeba vyplnit nebo opravit
                    tato pole:
                  </p>
                  <ul>
                    {error.name !== false ? <li>Jméno</li> : ""}
                    {error.phone !== false ? <li>Telefon</li> : ""}
                    {error.email !== false ? <li>Email</li> : ""}
                    {error.adress !== false ? <li>Adresa doručení</li> : ""}
                  </ul>
                </div>
              </div>
            ) : (
              ""
            )}
            <Button
              classes="cta full mb-3"
              disabled={
                !(
                  error.name === false &&
                  error.phone === false &&
                  error.email === false &&
                  error.adress === false
                )
              }
              value={
                loading ? (
                  <span className="loading">
                    <Loader />
                    Odesílám objednávku
                  </span>
                ) : checkout.shipping === "delivery" ? (
                  checkout.adress !== null ? (
                    `Objednat na ${checkout.adress}`
                  ) : (
                    "Objednat"
                  )
                ) : (
                  "Objednat"
                )
              }
              action={confirmOrder}
            />
            <p className="text-center mb-3 accept-gdpr">
              Potvrzením objednávky souhlasíte se zpracováním osobních údajů pro
              účely doručení objednávky.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
