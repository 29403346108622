import React from "react";
import { isNowOpen, isDayToday } from "../utils/openHours";
import { useMenuValue } from "../contexts";

export default function OpenHours() {
  const { dailyMenu, settings } = useMenuValue();

  function openHoursMessage() {
    if (settings) {
      const permanentMenuOpen = isNowOpen(
        settings.permanentmenudelivery[0].dayofweek
      );
      const dailyMenuOpen = isNowOpen(settings.dailymenudelivery[0].dayofweek);
      const menuDateSource = new Date(dailyMenu[0].ACF.datum_daily_menu);
      const menuDate = isDayToday(menuDateSource);
      let message = "";
      if (permanentMenuOpen && dailyMenuOpen && menuDate) {
        message = "Rozvážíme jídla z denního menu i ze stálé nabídky!";
      } else if (permanentMenuOpen) {
        message = "Rozvážíme jídla ze stálé nabídky!";
      } else {
        message = "V tuto chvíli nerozvážíme.";
      }
      return message;
    }
  }

  function isOpen() {
    if (settings) {
      const permanentMenuOpen = isNowOpen(
        settings.permanentmenudelivery[0].dayofweek
      );

      return permanentMenuOpen;
    } else {
      return null;
    }
  }

  return (
    <div
      className={`open-hours ${
        isOpen() === null ? "" : isOpen() ? "is-open" : "not-open"
      }`}
    >
      <span className="control"></span>
      {openHoursMessage()}
    </div>
  );
}
