import React from "react";

export default function SaleLabel({
  saleType,
  percent,
  slevaKc,
  requiredQty,
  menuPrice,
  menuWith,
}) {
  return (
    <span className="sale-label">
      {saleType === "fixed"
        ? `Sleva ${slevaKc} Kč`
        : saleType === "percent"
        ? `Sleva ${percent} %`
        : saleType === "menu"
        ? `V menu jen za ${menuPrice} Kč`
        : saleType === "xplusone"
        ? `${requiredQty}+1 zdarma`
        : ""}
    </span>
  );
}
