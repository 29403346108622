import React, { createContext, useContext, useState } from "react";

export const OrderStateContext = createContext();
export const OrderStateProvider = ({ children }) => {
  const [orderState, setOrderState] = useState("order");

  return (
    <OrderStateContext.Provider value={{ orderState, setOrderState }}>
      {children}
    </OrderStateContext.Provider>
  );
};

export const useOrderState = () => useContext(OrderStateContext);
