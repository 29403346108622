import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = firebase.initializeApp({
  apiKey: "AIzaSyDqcXHc8FgeLiVGjQEYhi4v2owOc5r5w7Y",
  authDomain: "rozvoz-pizzy-maxim.firebaseapp.com",
  databaseURL: "https://rozvoz-pizzy-maxim.firebaseio.com",
  projectId: "rozvoz-pizzy-maxim",
  storageBucket: "rozvoz-pizzy-maxim.appspot.com",
  messagingSenderId: "280851420648",
  appId: "1:280851420648:web:812450966d5cc71f21805d",
  measurementId: "G-NWYETXKMQQ",
});

// Initialize analytics
firebase.analytics();

// Auth and firestore references
const auth = firebase.auth();
const firestore = firebase.firestore();
const db = firebase.database();
const functions = firebase.functions();

export { firebaseConfig as firebase };
export { auth, firestore, functions, db };
