import React from "react";
import Button from "./Button";
import { useCart, useSelectedProduct } from "../contexts";
import SaleLabel from "./SaleLabel";

export default function SingleProduct({ jidlo, balne, cat, catName }) {
  const { cart, setCart } = useCart();
  const { setSelectedProduct } = useSelectedProduct();
  const {
    jidlo_name,
    jidlo_popis,
    jidlo_cena,
    mealCategory,
    saleType,
    percent,
    slevaKc,
    requiredQty,
    menuPrice,
    menuWith,
    sellable,
  } = jidlo;

  function addProduct(
    name,
    desc,
    price,
    balne,
    cat,
    catName,
    mealCategory,
    saleType,
    percent,
    slevaKc,
    requiredQty,
    menuPrice,
    menuWith
  ) {
    const product = {
      name: name,
      desc: desc,
      cat: cat,
      catName: catName,
      price: price,
      regularPrice: price,
      balne: balne,
      qty: 1,
      additives: [],
      mealCategory,
      saleType,
      percent,
      slevaKc,
      requiredQty,
      menuPrice,
      menuWith,
    };

    if (cat.includes("pizza") || cat.includes("burger")) {
      //pokud je item pizza, nejdrive zobrazujeme vyber ingredienci navic
      setSelectedProduct(product);
    } else {
      if (cart.length >= 1) {
        //pokud item neni pizza, kontrolujeme, jestli mame v kosiku nejake polozky
        const productToUpdate = cart.filter(
          // pokud ano, zkusime vyfiltrovat polozku, ktera je stejna jako ta, kterou chceme pridat
          (item) => item.name === product.name && item.cat === product.cat
        );

        if (productToUpdate.length > 0) {
          //pokud mame duplicitni polozku, zjistime index
          let idx = 0;
          for (let el of cart) {
            if (el.name === product.name && el.cat === product.cat) {
              break;
            } else {
              idx++;
            }
          }

          let newCart = [...cart];
          newCart[idx].qty = productToUpdate[0].qty + 1; // podle indexu u polozky v kosiku zvysime kvantitu
          setCart(newCart);
        } else {
          //pokud nemame duplicitni polozku, pridame do kosiku
          setCart((prevState) => [...prevState, product]);
        }
      } else {
        setCart((prevState) => [...prevState, product]); // pokud item neni pizza, rovnou pridame do kosiku
      }
    }
  }

  function inCart() {
    if (cart.length >= 1) {
      const productsInCart = cart.filter(
        (i) => i.name === jidlo_name && i.catName === catName
      );
      if (productsInCart.length > 0) {
        const productCount = productsInCart.reduce((acc, el) => {
          acc += el.qty;
          return acc;
        }, 0);
        return productCount;
      }
    }
  }

  const productCount = inCart();
  return (
    <>
      <div className="product-item">
        <div className="grid-x">
          <div className="small-12 cell product-head">
            <span className="product-title">
              {jidlo_name}
              {saleType !== "none" ? (
                <SaleLabel
                  saleType={saleType}
                  percent={percent}
                  slevaKc={slevaKc}
                  requiredQty={requiredQty}
                  menuPrice={menuPrice}
                  menuWith={menuWith}
                />
              ) : null}
            </span>
            <span className="product-description">{jidlo_popis}</span>
          </div>
        </div>
        <div className="grid-x align-middle">
          <div className="small-4">
            {saleType === "fixed" ? (
              <span className="product-price">
                <del>{jidlo_cena} Kč</del>{" "}
                {parseInt(jidlo_cena) - parseInt(slevaKc)} Kč
              </span>
            ) : saleType === "percent" ? (
              <span className="product-price">
                <del>{jidlo_cena} Kč</del>{" "}
                {(
                  parseInt(jidlo_cena) -
                  (parseInt(jidlo_cena) / 100) * parseInt(percent)
                ).toFixed(0)}{" "}
                Kč
              </span>
            ) : (
              <span className="product-price">{jidlo_cena} Kč</span>
            )}
          </div>
          <div className="small-8 text-right">
            {productCount > 0 && (
              <span className="product-qty">
                <svg
                  width="13"
                  height="10"
                  viewBox="0 0 13 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.5L4.66667 8L12 1"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>{" "}
                v košíku {productCount} ks
              </span>
            )}
            <Button
              value={sellable ? "Vybrat" : "Vyprodáno"}
              action={() => {
                addProduct(
                  jidlo_name,
                  jidlo_popis,
                  jidlo_cena,
                  balne,
                  cat,
                  catName,
                  mealCategory,
                  saleType,
                  percent,
                  slevaKc,
                  requiredQty,
                  menuPrice,
                  menuWith
                );
              }}
              disabled={!sellable}
            />
          </div>
        </div>
      </div>
    </>
  );
}
