import React from "react";
import { Pizza } from "./svg";

export default function EmptyCart() {
  return (
    <div className="empty-cart">
      <Pizza />
      <span>Vaše objednávka zatím neobsahuje žádné položky.</span>
    </div>
  );
}
