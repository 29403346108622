import React from "react";

export default function TextInput({
  id,
  label,
  value,
  action,
  placeholder,
  required,
  type,
  validation,
  error
}) {
  return (
    <div className="input-field text">
      <label htmlFor={id}>
        {label}
        {required ? <span className="required"> *</span> : ""}
      </label>
      <input
        className={
          error !== null ? (error === false ? "valid" : "invalid") : ""
        }
        type={type}
        id={id}
        name={id}
        onChange={action}
        placeholder={placeholder}
        required={required}
        value={value}
        onBlur={validation}
      />
      {error && <p className="error">{error}</p>}
    </div>
  );
}
