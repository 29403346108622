import React from "react";
import QtyControl from "./QtyControl";
import { qtyPlus, qtyMinus, calculateItemPrice } from "../utils";

export default function CartItem({ i, controls, cart, setCart }) {
  return (
    <div className="cart-item">
      <div className="grid-x align-justify align-middle">
        <div className="cell shrink">
          <div className="cart-item--headline">
            {i.catName} {i.name}
          </div>
          {i.additives.length >= 1 && (
            <div className="cart-item--additives">
              <span> + </span>
              {i.additives.map((ad, index) => (
                <span key={`add-${index}`}>
                  {(index ? ", " : "") + ad.name}
                </span>
              ))}
            </div>
          )}
        </div>
        <div className="cell shrink">
          <div className="grid-x align-middle">
            {controls ? (
              <div className="cell shrink">
                <QtyControl
                  type="minus"
                  action={() => {
                    qtyMinus(i, cart, setCart);
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <span
              className={`cart-item--price ${
                controls ? "text-center" : "text-right"
              }`}
            >
              {i.qty > 1 ? (
                <span className="cart-item--qty">{i.qty}× </span>
              ) : (
                ""
              )}
              {calculateItemPrice(i, cart)} Kč
            </span>
            {controls ? (
              <div className="cell shrink">
                <QtyControl
                  type="plus"
                  action={() => {
                    qtyPlus(i, cart, setCart);
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
