import React, { useEffect } from "react";
import Close from "./Close";
import Button from "./Button";
import { useSelectedProduct, useMenuValue, useCart } from "../contexts";
import IngredienceItem from "./IngredienceItem";
import { isEqual } from "lodash";

export default function IngredienceSelect() {
  const { selectedProduct, setSelectedProduct } = useSelectedProduct();
  const { ingredience, burgerIngredience } = useMenuValue();
  const { cart, setCart } = useCart();
  const ings = ingredienceSelect();

  useEffect(() => {
    console.log(selectedProduct);
  }, [cart]);

  function ingredienceSelect() {
    if (selectedProduct.cat.includes("pizza")) {
      return ingredience;
    } else {
      return burgerIngredience;
    }
  }

  function addToCart() {
    if (cart.length >= 1) {
      const productToUpdate = cart.filter(
        (item) =>
          item.name === selectedProduct.name &&
          item.cat === selectedProduct.cat &&
          item.additives.length === selectedProduct.additives.length &&
          isEqual(item.additives, selectedProduct.additives)
      );
      if (productToUpdate.length > 0) {
        let idx = 0;
        for (let el of cart) {
          if (
            el.name === selectedProduct.name &&
            el.cat === selectedProduct.cat &&
            el.additives.length === selectedProduct.additives.length &&
            isEqual(el.additives, selectedProduct.additives)
          ) {
            break;
          } else {
            idx++;
          }
        }

        let newCart = [...cart];
        newCart[idx].qty = productToUpdate[0].qty + 1;
        setCart(newCart);
        setSelectedProduct();
      } else {
        setCart((prevState) => [...prevState, selectedProduct]);
        setSelectedProduct();
      }
    } else {
      setCart((prevState) => [selectedProduct]);
      setSelectedProduct();
    }
  }

  return (
    <div className="modal-content">
      <span className="modal-headline">
        {selectedProduct.cat.includes("pizza")
          ? "Přidejte si na pizzu to, co vám chutná"
          : "Co k tomu?"}
      </span>
      <div className="ingredience-select">
        <span className="modal-product-title">{selectedProduct.name}</span>
        <span className="modal-product-description">
          {selectedProduct.desc}
          {selectedProduct.additives.length >= 1 && <span> + </span>}
          {selectedProduct.additives.length >= 1 &&
            selectedProduct.additives.map((ad, index) => (
              <span key={`add-${index}`}>{(index ? ", " : "") + ad.name}</span>
            ))}
        </span>
        <div className="grid-container full ingrediences">
          <div className="grid-x grid-margin-x">
            {ings &&
              ings[0].ACF.jidlo.map((item, index) => (
                <IngredienceItem
                  key={item.jidlo_name}
                  item={item}
                  idx={index}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="scroll-box-overlay"></div>
      <div className="grid-container full">
        <div className="grid-x align-center">
          <Button
            classes="cta"
            styles="display: inline-block;padding: 20px 40px;"
            value={
              selectedProduct.additives.length >= 1
                ? "Objednat s těmito přísadami"
                : "Objednat bez přísad"
            }
            action={addToCart}
          />
        </div>
      </div>
      <Close
        label="Zavřít"
        action={() => {
          setSelectedProduct();
        }}
      />
    </div>
  );
}
