import React from "react";
import Cart from "./Cart";
import { useCart } from "../contexts";

export default function CheckoutCart() {
  const { cart } = useCart();
  return (
    <>
      <div className="sticky-cart" id="cart">
        <span className="cart-headline">Vaše objednávka</span>
        <Cart cart={cart} />
      </div>
    </>
  );
}
